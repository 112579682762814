import { Component, Input, OnInit } from '@angular/core';
import { Review } from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import { TableConfig } from '../../elements/data-table/data-table.interface';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements OnInit {
  reviews: Review[] = [];
  totalItems: number = 0;
  itemsPerPage = 15;

  @Input()
  latestOnly = false;

  @Input()
  restaurantStrid: string | undefined;

  @Input()
  employeeGuid: string | undefined;

  private _tableConfig: TableConfig | undefined = undefined;

  get tableConfig() {
    return this._tableConfig;
  }

  constructor(private apiService: ApiService) {}

  loadData(sort?: SortEvent) {
    this.apiService
      .getReviews(
        {
          strid: this.restaurantStrid,
          guid: this.employeeGuid,
        },
        sort?.sortBy,
        sort?.sortDirection,
        sort?.page,
        this.itemsPerPage
      )
      .subscribe(
        (response) => {
          this.reviews = response.reviews;
          this.totalItems = response.total;
        },
        () => {
          console.log('err');
        }
      );
  }

  ngOnInit(): void {
    this._tableConfig = {
      title: this.latestOnly ? 'Latest reviews' : 'Reviews',
      paging: !this.latestOnly,
      headerButtons: this.latestOnly
        ? [
            {
              title: 'All Reviews',
              icon: 'fa-external-link-alt',
              link: ['/reviews'],
            },
          ]
        : [
            { title: 'Filter', icon: 'fa-filter' },
            //{ title: 'Export', icon: 'fa-external-link-alt' },
          ],
      columns: [
        {
          title: 'Employee',
          property: 'employee',
          sortable: !this.latestOnly && true,
          filterable: false,
          fromObject: true,
          useFirstLastName: true,
          link: {
            prefix: '/employees',
            property: 'guid',
          },
        },
        {
          title: 'Restaurant',
          property: 'restaurant',
          sortable: !this.latestOnly && true,
          filterable: false,
          fromObject: true,
          hidden: this.restaurantStrid !== undefined,
          link: {
            prefix: '/restaurants',
            property: 'strid',
          },
        },
        {
          title: 'Created',
          property: 'createdOn',
          sortable: !this.latestOnly && true,
          filterable: false,
          isDate: true,
        },
        {
          title: 'Review',
          property: 'review',
          sortable: !this.latestOnly && false,
          filterable: false,
          wrap: true,
        },
        {
          title: 'Rating',
          property: 'stars',
          sortable: !this.latestOnly && true,
          filterable: false,
          isRating: true,
        },
      ],
    };

    this.loadData();
  }
}
