import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { DropzoneResponse } from '../../../../interfaces/api.interface';
import { ModalService } from '../../../modal/modal.service';
import { ApiService } from '../../../_services/api.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class RestaurantEditComponent implements OnInit {
  restaurantForm = this.formBuilder.group({
    id: undefined,
    name: ['', Validators.required],
    phone: '',
    address: '',
    email: '',
    directPayment: false,
    paymentCommission: '',
  });
  restaurantLogo = 'assets/img/team/avatar.png';
  submitted = false;
  error = '';
  loading = false;
  dropZoneEvent = new EventEmitter<string>();

  get f() {
    return this.restaurantForm?.controls ?? {};
  }

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const strid = params.get('strid') ?? '';
      if (strid && strid != 'new') {
        this.apiService
          .getRestaurant(strid)
          .pipe(
            tap((restaurant) => {
              this.restaurantLogo = restaurant.logo;
              this.restaurantForm.patchValue(restaurant);
            })
          )
          .subscribe();
      }
    });
  }

  onResponse(response: DropzoneResponse) {
    let data;
    if (response?.guid) {
      data = {
        logo: response.guid,
        ...this.restaurantForm.value,
      };
    } else {
      data = this.restaurantForm.value;
    }

    this.loading = true;
    this.apiService.saveRestaurant(data).subscribe(
      (response) => {
        this.loading = false;
        this.router.navigate(['/restaurants']);
      },
      (err) => {
        console.log(err);
        this.loading = false;
        this.modalService.error('Server error: ' + err);
      }
    );
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.restaurantForm?.invalid) {
      return;
    }

    this.dropZoneEvent.emit('processQueue');
  }
}
