import { Component, OnInit } from '@angular/core';
import { CountUpOptions } from 'countup.js';
import { Subject } from 'rxjs';
import { Chart, ChartSelector, User } from '../../../interfaces/api.interface';
import { utils } from '../../_helpers/utils';
import { WindowRef } from '../../_helpers/window-ref';
import { ApiService } from '../../_services/api.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  optsMoney: CountUpOptions = {
    decimalPlaces: 2,
    suffix: '€',
    duration: 1,
  };
  opts: CountUpOptions = {
    decimalPlaces: 0,
    duration: 1,
  };
  months: ChartSelector[] = [];
  chart = new Subject<Chart>();
  totalTips = 0;
  averageTip = 0;
  tipCount = 0;
  user: User = new User();

  constructor(
    private winRef: WindowRef,
    private apiService: ApiService,
    private authenticationService: AuthenticationService
  ) {
    for (let i = 0; i < 12; i++) {
      const d = new Date();
      d.setDate(1);
      d.setMonth(d.getMonth() - i);

      this.months.push({
        value: d.getFullYear() + '-' + (d.getMonth() + 1) + '-1',
        text: d.toLocaleString('default', { month: 'long', year: 'numeric' }),
      });
    }
    if (this.authenticationService.userValue) {
      this.user = this.authenticationService.userValue;
    }
  }

  onChartPeriodChange(data: string) {
    this.getTotalSales(data);
  }

  getTotalSales(value?: string) {
    if (!value) {
      const d = new Date();
      value = d.getFullYear() + '-' + (d.getMonth() + 1) + '-1';
    }
    this.apiService.getTotalSales(value).subscribe(
      (response) => {
        this.chart.next({
          data: response.chart,
        });
        this.totalTips = response.sales.total;
        this.averageTip = response.sales.average;
        this.tipCount = response.sales.count;
      },
      () => {
        console.log('err');
      }
    );
  }

  ngOnInit(): void {
    this.getTotalSales();
  }

  weeklySales() {
    const ECHART_BAR_WEEKLY_SALES = '.echart-bar-weekly-sales';
    const $echartBarWeeklySales = document.querySelector(
      ECHART_BAR_WEEKLY_SALES
    );

    if ($echartBarWeeklySales) {
      // Get options from data attribute
      const userOptions = utils.getData($echartBarWeeklySales, 'options');
      const data = [120, 200, 150, 80, 70, 110, 120]; // Max value of data

      const yMax = Math.max(...data);
      const dataBackground = data.map(function () {
        return yMax;
      });
      const chart = this.winRef.nativeWindow.echarts.init(
        $echartBarWeeklySales
      ); // Default options

      const getDefaultOptions = () => {
        return {
          tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            formatter: '{b1}: {c1}',
            transitionDuration: 0,
            backgroundColor: utils.getGrays()['100'],
            borderColor: utils.getGrays()['300'],
            textStyle: {
              color: utils.getColor('dark'),
            },
            borderWidth: 1,
            position: (
              pos: number[],
              params: any,
              dom: any,
              rect: any,
              size: any
            ) => utils.getPosition(pos, params, dom, rect, size),
          },
          xAxis: {
            type: 'category',
            data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            boundaryGap: false,
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisPointer: {
              type: 'none',
            },
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisPointer: {
              type: 'none',
            },
          },
          series: [
            {
              type: 'bar',
              barWidth: '5px',
              barGap: '-100%',
              itemStyle: {
                barBorderRadius: 10,
                color: utils.getGrays()['200'],
              },
              data: dataBackground,
              animation: false,
              emphasis: {
                itemStyle: {
                  color: utils.getGrays()['200'],
                },
              },
            },
            {
              type: 'bar',
              barWidth: '5px',
              itemStyle: {
                barBorderRadius: 10,
                color: utils.getColor('primary'),
              },
              data: data,
              z: 10,
              emphasis: {
                itemStyle: {
                  color: utils.getColor('primary'),
                },
              },
            },
          ],
          grid: {
            right: 5,
            left: 10,
            top: 0,
            bottom: 0,
          },
        };
      };

      this.echartSetOption(chart, userOptions, getDefaultOptions);
    }
  }

  echartSetOption(chart: any, userOptions: any, getDefaultOptions: any) {
    const themeController = document.body; // Merge user options with lodash

    chart.setOption(
      this.winRef.nativeWindow._.merge(getDefaultOptions(), userOptions)
    );
    themeController.addEventListener('clickControl', (_ref16) => {
      const control = (_ref16 as any)?.detail.control;

      if (control === 'theme') {
        chart.setOption(
          this.winRef.nativeWindow._.merge(getDefaultOptions(), userOptions)
        );
      }
    });
  }
}
