<pre-login>
    <a class="d-flex flex-center mb-4" href="/"><span
            class="font-sans-serif fw-bolder fs-4 d-inline-block">TipEasy</span></a>

    <div *ngIf="!done" class="card theme-wizard mb-5">
        <div class="card-header bg-light pt-3 pb-2">
            <ul class="nav justify-content-between nav-wizard">
                <li *ngFor="let step of steps; let i = index" class="nav-item" (click)="changeStep(i)">
                    <a class="nav-link fw-semi-bold" href="{{ step.tab }}" [class.active]="currentStep == i"
                        [class.disabled]="i > stepsComplete" [class.done]="currentStep > i" [attr.data-bs-step]="i"
                        data-bs-toggle="tab" data-wizard-step="data-wizard-step">
                        <span class="nav-item-circle-parent">
                            <span class="nav-item-circle">
                                <span class="fas" [ngClass]="step.icon"></span>
                            </span>
                        </span>
                        <span class="d-none d-md-block mt-1 fs--1">{{ step.title }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="card-body py-4" id="wizard-controller">
            <div class="tab-content">
                <div class="tab-pane active px-sm-3 px-md-5" role="tabpanel" aria-labelledby="wizard-tab1"
                    id="wizard-tab1">
                    <form [formGroup]="formGroups[0]">
                        <div class="mb-3"><label class="form-label" for="firstName">First Name</label><input
                                class="form-control" type="text"
                                [ngClass]="{ 'is-invalid': validation && f?.firstName?.errors }" name="firstName"
                                placeholder="First Name ..." id="firstName" formControlName="firstName" />
                            <div *ngIf="validation && f?.firstName?.errors" class="invalid-feedback">
                                <div *ngIf="f?.firstName?.errors?.required">First name is required!</div>
                            </div>
                        </div>
                        <div class="mb-3"><label class="form-label" for="lastName">Last Name</label><input
                                class="form-control" type="text"
                                [ngClass]="{ 'is-invalid': validation && f?.lastName?.errors }" name="lastName"
                                placeholder="Last Name ..." id="lastName" formControlName="lastName" />
                            <div *ngIf="validation && f?.lastName?.errors" class="invalid-feedback">
                                <div *ngIf="f?.lastName?.errors?.required">Last name is required!</div>
                            </div>
                        </div>
                        <div class="mb-3"><label class="form-label" for="username">Email</label><input
                                class="form-control" type="email" name="username" placeholder="Email address ..."
                                [ngClass]="{ 'is-invalid': (validation && f?.username?.errors) || f?.username?.errors?.taken }"
                                required="required" id="email" formControlName="username" />
                            <div *ngIf="f?.username?.errors" class="invalid-feedback">
                                <div *ngIf="validation && f?.username?.errors?.email">Not a valid email address!</div>
                                <div *ngIf="validation && f?.username?.errors?.required">Email is required!</div>
                                <div *ngIf="f?.username?.errors?.taken">Email is already registered!</div>
                            </div>
                        </div>
                        <div class="row g-2">
                            <div class="col-6">
                                <div class="mb-3"><label class="form-label" for="password">Password</label><input
                                        class="form-control" type="password" name="password" placeholder="Password"
                                        [ngClass]="{ 'is-invalid': validation && f?.password?.errors }" id="password"
                                        formControlName="password" />
                                    <div *ngIf="validation && f?.password?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.password?.errors?.required">Password is required!</div>
                                        <div *ngIf="f?.password?.errors?.tooShort">Password too short!</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mb-3"><label class="form-label" for="password-confirm">Confirm
                                        Password</label><input class="form-control" type="password"
                                        name="passwordConfirm"
                                        [ngClass]="{ 'is-invalid': validation && f?.confirmPassword?.errors }"
                                        placeholder="Confirm Password" id="password-confirm"
                                        formControlName="confirmPassword" />
                                    <div *ngIf="validation && f?.confirmPassword?.errors" class="invalid-feedback">
                                        <div *ngIf="f?.confirmPassword?.errors?.notSame">Passwords do not match!</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-check"><input class="form-check-input" type="checkbox" name="terms"
                                [ngClass]="{ 'is-invalid': validation && f?.acceptTerms?.errors }" id="acceptTerms"
                                formControlName="acceptTerms" /><label class="form-check-label" for="acceptTerms">I
                                accept the <a href="#!">terms </a>and <a href="#!">privacy policy</a></label></div>
                    </form>
                </div>
                <div class="tab-pane px-sm-3 px-md-5" role="tabpanel" aria-labelledby="wizard-tab2" id="wizard-tab2">
                    <form [formGroup]="formGroups[1]">
                        <div class="mb-3"><label class="form-label" for="companyName">Company Name</label><input
                                class="form-control" type="text"
                                [ngClass]="{ 'is-invalid': validation && f?.companyName?.errors }" name="companyName"
                                placeholder="Company Name..." id="companyName" formControlName="companyName" />
                            <div *ngIf="validation && f?.companyName?.errors" class="invalid-feedback">
                                <div *ngIf="f?.companyName?.errors?.required">Company name is required!</div>
                            </div>
                        </div>
                        <div class="mb-3"><label class="form-label" for="companyRegistrationNumber">Company Registration
                                Number</label><input class="form-control" type="text"
                                [ngClass]="{ 'is-invalid': validation && f?.companyRegistrationNumber?.errors }"
                                name="companyRegistrationNumber" placeholder="Company Registration Number..."
                                id="companyRegistrationNumber" formControlName="companyRegistrationNumber" />
                            <div *ngIf="validation && f?.companyRegistrationNumber?.errors" class="invalid-feedback">
                                <div *ngIf="f?.companyRegistrationNumber?.errors?.required">Company registration number
                                    is
                                    required!</div>
                            </div>
                        </div>

                        <div class="mb-3"><label class="form-label" for="phone">Contact Phone</label><input
                                class="form-control" type="text"
                                [ngClass]="{ 'is-invalid': validation && f?.phone?.errors }" name="phone"
                                placeholder="+371 ..." id="phone" formControlName="phone" />
                            <div *ngIf="validation && f?.phone?.errors" class="invalid-feedback">
                                <div *ngIf="f?.phone?.errors?.required">Phone number is required!</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="tab-pane px-sm-3 px-md-5" role="tabpanel" aria-labelledby="wizard-tab3" id="wizard-tab3">
                    <div class="mb-3"><label class="form-label" for="firstName">First Name</label>
                        <div>{{ formGroups[0].controls.firstName.value }}</div>
                    </div>
                    <div class="mb-3"><label class="form-label" for="lastName">Last Name</label>
                        <div>{{ formGroups[0].controls.lastName.value }}</div>
                    </div>
                    <div class="mb-3"><label class="form-label" for="email">Email</label>
                        <div>{{ formGroups[0].controls.username.value }}</div>
                    </div>
                    <div class="mb-3"><label class="form-label" for="companyName">Company Name</label>
                        <div>{{ formGroups[1].controls.companyName.value }}</div>
                    </div>
                    <div class="mb-3"><label class="form-label" for="companyRegistrationNumber">Company Registration
                            Number</label>
                        <div>{{ formGroups[1].controls.companyRegistrationNumber.value }}</div>
                    </div>
                    <div class="mb-3"><label class="form-label" for="phone">Contact Phone</label>
                        <div>{{ formGroups[1].controls.phone.value }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer bg-light" [class.d-none]="currentStep > steps.length-1">
            <div class="px-sm-3 px-md-5">
                <ul class="pager wizard list-inline mb-0">
                    <li class="previous"><button class="btn btn-link ps-0" type="button" (click)="prevStep()"
                            [class.d-none]="currentStep==0"><span class="fas fa-chevron-left me-2"
                                data-fa-transform="shrink-3"></span>Prev</button></li>
                    <li class="next"><button (click)="nextStep()" class="btn btn-primary px-5 px-sm-6" type="submit">{{
                            currentStep < steps.length-1 ? 'Next' : 'Submit' }}<span class="fas fa-chevron-right ms-2"
                                data-fa-transform="shrink-3"></span></button></li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="done" class="theme-wizard tab-pane text-center px-sm-3 px-md-5" role="tabpanel"
        aria-labelledby="wizard-tab4" id="wizard-tab4">
        <div class="wizard-lottie-wrapper">
            <div class="wizard-lottie mx-auto my-3">
                <ng-lottie [options]="lottieOptions"></ng-lottie>
            </div>
        </div>
        <h4 class="mb-1">Your account registration has been submitted!</h4>
        <p>You will be contacted by our staff in the coming days to confirm all the details and activate the account</p>
        <p>For any questions please call our office (9:00 - 17:00, Mon - Fri) +371 26573635</p>

        <button [routerLink]="['/']" class="btn btn-primary px-5 px-sm-6">
            Return to login page</button>
    </div>

</pre-login>