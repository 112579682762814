<div class="row g-0">
  <div class="col-lg-12 mb-3">
    <chart [chartSelector]="months" [chart]="chart.asObservable()" (onSelectorChange)="onChartPeriodChange($event)">
    </chart>
  </div>
</div>


<div class="row g-3 mb-3">
  <div class="col-sm-6 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-1.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Total tips</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning" [countUp]="totalTips"
          [options]="optsMoney">0
        </div><a *ngIf="user?.role !== 'Employee'" class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See
          all<span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-2.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Average tip</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info" [countUp]="averageTip"
          [options]="optsMoney">0
        </div><a *ngIf="user?.role !== 'Employee'" class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See
          all<span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-3.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Tip count</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info" [countUp]="tipCount" [options]="opts">0
        </div><a *ngIf="user?.role !== 'Employee'" class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See
          all<span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>
</div>

<div class="row g-3 mb-3">
  <div class="col-sm-12">
    <reviews [latestOnly]=true></reviews>
  </div>
</div>