import { Injectable } from '@angular/core';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modal: ModalComponent[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modal.push(modal);
  }

  remove(modal: any) {
    const idx = this.modal.indexOf(modal, 0);
    if (idx > -1) {
      this.modal.splice(idx, 1);
    }
  }

  private _findModal(id = 'global') {
    return this.modal.find((m) => m.id === id);
  }

  open(content?: string) {
    this._findModal()?.open(content);
  }

  error(content?: string) {
    this._findModal()?.error(content);
  }

  success(content?: string) {
    this._findModal()?.success(content);
  }

  info(content?: string) {
    this._findModal()?.info(content);
  }

  close() {
    this._findModal()?.close();
  }

  confirmation(content: string, callback: () => void) {
    this._findModal()?.confirmation(content, callback);
  }

  popup(id: string) {
    this._findModal(id)?.popup();
  }
}
