import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get<User[]>(environment.apiUrl + `/api/users`);
  }

  getById(id: number) {
    return this.http.get<User>(environment.apiUrl + `/api/users/${id}`);
  }
}
