import { Role } from '../../../interfaces/api.interface';

export interface Link {
  prefix?: string;
  property: string;
}

export interface TableHeaderButtons {}

export interface TableColumnAvatar {
  property: string;
}

export type LinkAsFn = (item: any) => string | null;
export type ClicksFn = (item: any) => void;

export interface LinkAsStr {
  prefix: string;
  property: string;
}

export interface TableColumnBadgeWithText {
  class: string;
  text: string;
}
export interface TableColumnBadgeWithIcon {
  class: string;
  icon: string;
}

export interface TableColumnBadge {
  [key: string]: TableColumnBadgeWithText | TableColumnBadgeWithIcon;
}

export interface TableColumnTooltip {
  property: string;
}

export enum TableColumnAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export interface TableColumn {
  title: string;
  property: string;
  sortable: boolean;
  filterable: boolean;
  visibleTo?: Role[];
  propertyValueFrom?: string;
  useFirstLastName?: boolean;
  fromObject?: boolean;
  hidden?: boolean;
  isDate?: boolean;
  isTime?: boolean;
  badge?: TableColumnBadge;
  avatar?: TableColumnAvatar;
  link?: LinkAsFn | LinkAsStr;
  wrap?: boolean;
  isRating?: boolean;
  tooltip?: TableColumnTooltip;
  isMoney?: boolean;
  align?: TableColumnAlign;
}

export interface TableColumnMoreOptionsShowOnlyIf {
  property: string;
  value: string[];
}

export interface TableColumnMoreOptionsClick {
  property: string;
  fn: ClicksFn;
}

export interface TableColumnMoreOptions {
  title: string;
  class?: string;
  link?: LinkAsFn | LinkAsStr;
  click?: TableColumnMoreOptionsClick;
  showOnlyIfNot?: TableColumnMoreOptionsShowOnlyIf;
  showOnlyIf?: TableColumnMoreOptionsShowOnlyIf;
}

export interface TableColumnMore {
  moreOptions: true;
  options: TableColumnMoreOptions[];
}
export interface TableConfig {
  title: string;
  paging?: boolean;
  headerButtons: TableHeaderButtons[];
  columns: (TableColumn | TableColumnMore)[];
}
