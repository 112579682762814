<span
    class="badge"
    [ngClass]="class"
    data-bs-toggle="tooltip"
    [attr.data-bs-placement]="tooltipPosition"
    [attr.title]="tooltipTitle"
>
    {{ text }}
    <span
        *ngIf="icon"
        class="ms-1 fas"
        [ngClass]="icon"
        data-fa-transform="shrink-2">
    </span>
</span>