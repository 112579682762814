<!--
<div class="card mt-4">
    <h4 class="card-header">Home</h4>
    <div class="card-body">
        <p>You're logged in with Angular 10 & JWT!!</p>
        <p>Your role is: <strong>{{user.role}}</strong>.</p>
        <p>This page can be accessed by <u>all authenticated users</u>.</p>
        <p class="mb-1">Current user from secure api end point:</p>
        <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
        <ul *ngIf="userFromApi">
            <li>{{userFromApi.firstName}} {{userFromApi.lastName}}</li>
        </ul>
    </div>
</div>
-->

<!-- ===============================================-->
<!--    Main Content-->
<!-- ===============================================-->
<main class="main dark" id="top">
  <div class="container-fluid" data-layout="container">
    <nav class="navbar navbar-light navbar-vertical navbar-expand-xl">
      <div class="d-flex align-items-center">
        <div class="toggle-icon-wrapper">
          <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip"
            data-bs-placement="left" title="Toggle Navigation"><span class="navbar-toggle-icon"><span
                class="toggle-line"></span></span></button>
        </div>
        <a class="navbar-brand" href="/">
          <div class="d-flex align-items-center py-3">
            <img class="" src="assets/TipEasy_logo_Light_horizontal.svg" alt="TipEasy" width="150">
          </div>
        </a>
      </div>
      <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
        <div class="navbar-vertical-content scrollbar">
          <ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            <li class="nav-item">
              <!-- label-->
              <div class="row navbar-vertical-label-wrapper mb-2">
                <!--<div class="col-auto navbar-vertical-label">Dashboard
                    </div>-->
                <div class="col ps-0">
                  <hr class="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/statistics']" role="button">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                      class="fas fa-chart-pie"></span></span><span class="nav-link-text ps-1">Statistics</span>
                </div>
              </a>
              <a *ngIf="user?.role !== 'Employee'" class="nav-link" [routerLinkActive]="['active']"
                [routerLink]="['/restaurants']" role="button">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                      class="fas fa-utensils"></span></span><span class="nav-link-text ps-1">Restaurants</span>
                </div>
              </a>
              <a *ngIf="user?.role !== 'Employee'" class="nav-link" [routerLinkActive]="['active']"
                [routerLink]="['/employees']" role="button">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                      class="fas fa-user-friends"></span></span><span class="nav-link-text ps-1">Employees</span>
                </div>
              </a>
              <a *ngIf="user?.role === 'Employee'" class="nav-link" [routerLinkActive]="['active']"
                [routerLink]="['/workplaces']" role="button">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                      class="fas fa-user-friends"></span></span><span class="nav-link-text ps-1">My Work Places</span>
                </div>
              </a>
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/reviews']" role="button">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                      class="fas fa-star"></span></span><span class="nav-link-text ps-1">Reviews</span>
                </div>
              </a>

              <div *ngIf="user?.role != 'Admin'">
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/payments']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-hand-holding-usd"></span></span><span class="nav-link-text ps-1">Payments</span>
                  </div>
                </a>
              </div>

              <div *ngIf="user?.role == 'Admin'">
                <div class="row mb-3"></div>

                <div class="row navbar-vertical-label-wrapper mb-2">
                  <div class="col-auto navbar-vertical-label">Admin
                  </div>
                  <div class="col ps-0">
                    <hr class="mb-0 navbar-vertical-divider" />
                  </div>
                </div>
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/users']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-users-cog"></span></span><span class="nav-link-text ps-1">Users</span>
                  </div>
                </a>
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/transactions']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-list-alt"></span></span><span class="nav-link-text ps-1">Transactions</span>
                  </div>
                </a>
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/balance']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-dollar-sign"></span></span><span class="nav-link-text ps-1">Balance</span>
                  </div>
                </a>
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/export']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-list-alt"></span></span><span class="nav-link-text ps-1">Export List</span>
                  </div>
                </a>
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/admin', 'settings']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-cog"></span></span><span class="nav-link-text ps-1">Settings</span>
                  </div>
                </a>
              </div>

              <div *ngIf="user?.role == 'Manager'">
                <div class="row mb-3"></div>

                <div class="row navbar-vertical-label-wrapper mb-2">
                  <div class="col-auto navbar-vertical-label">Manager
                  </div>
                  <div class="col ps-0">
                    <hr class="mb-0 navbar-vertical-divider" />
                  </div>
                </div>
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/transactions']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-list-alt"></span></span><span class="nav-link-text ps-1">Transactions</span>
                  </div>
                </a>
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/balance']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-dollar-sign"></span></span><span class="nav-link-text ps-1">Balance</span>
                  </div>
                </a>
                <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/export']" role="button">
                  <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                        class="fas fa-list-alt"></span></span><span class="nav-link-text ps-1">Export List</span>
                  </div>
                </a>
              </div>

              <div class="row navbar-vertical-label-wrapper mb-2">
                <!--<div class="col-auto navbar-vertical-label">Dashboard
                    </div>-->
                <div class="col ps-0">
                  <hr class="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/contact']" role="button">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                      class="fas fa-phone-square-alt"></span></span><span class="nav-link-text ps-1">Contact Us</span>
                </div>
              </a>
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/faq']" role="button">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                      class="fas fa-question-circle"></span></span><span class="nav-link-text ps-1">FAQ</span>
                </div>
              </a>

              <div class="row navbar-vertical-label-wrapper mb-2">
                <!--<div class="col-auto navbar-vertical-label">Dashboard
                    </div>-->
                <div class="col ps-0">
                  <hr class="mb-0 navbar-vertical-divider" />
                </div>
              </div>
              <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/logout']" role="button">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span
                      class="fas fa-sign-out-alt"></span></span><span class="nav-link-text ps-1">Logout</span>
                </div>
              </a>

            </li>

          </ul>
        </div>
      </div>
    </nav>
    <div class="content">
      <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand">

        <button class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button"
          data-bs-toggle="collapse" data-bs-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse"
          aria-expanded="false" aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span
              class="toggle-line"></span></span></button>
        <a class="navbar-brand me-1 me-sm-3" href="index.html">
          <div class="d-flex align-items-center">
            <img src="assets/TipEasy_logo_Light_horizontal.svg">
          </div>
        </a>
        <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
          <!--<li class="nav-item dropdown">
            <a class="nav-link notification-indicator notification-indicator-primary px-0 icon-indicator"
              id="navbarDropdownNotification" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false"><span class="fas fa-bell" data-fa-transform="shrink-6"
                style="font-size: 33px;"></span></a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-card"
              aria-labelledby="navbarDropdownNotification">
              <div class="card card-notification shadow-none">
                <div class="card-header">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto">
                      <h6 class="card-header-title mb-0">Notifications</h6>
                    </div>
                    <div class="col-auto"><a class="card-link fw-normal" href="#">Mark all as read</a></div>
                  </div>
                </div>
                <div class="scrollbar-overlay" style="max-height:19rem">
                  <div class="list-group list-group-flush fw-normal fs--1">
                    <div class="list-group-title border-bottom">NEW</div>
                    <div class="list-group-item">
                      <a class="notification notification-flush notification-unread" href="#!">
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <img class="rounded-circle" src="assets/img/team/1-thumb.png" alt="" />

                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1"><strong>Emma Watson</strong> replied to your comment : "Hello world 😍"</p>
                          <span class="notification-time"><span class="me-2" role="img" aria-label="Emoji">💬</span>Just
                            now</span>

                        </div>
                      </a>

                    </div>
                    <div class="list-group-item">
                      <a class="notification notification-flush notification-unread" href="#!">
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <div class="avatar-name rounded-circle"><span>AB</span></div>
                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1"><strong>Albert Brooks</strong> reacted to <strong>Mia Khalifa's</strong>
                            status</p>
                          <span class="notification-time"><span
                              class="me-2 fab fa-gratipay text-danger"></span>9hr</span>

                        </div>
                      </a>

                    </div>
                    <div class="list-group-title border-bottom">EARLIER</div>
                    <div class="list-group-item">
                      <a class="notification notification-flush" href="#!">
                        <div class="notification-avatar">
                          <div class="avatar avatar-2xl me-3">
                            <img class="rounded-circle" src="assets/img/icons/weather-sm.jpg" alt="" />

                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1">The forecast today shows a low of 20&#8451; in California. See today's
                            weather.</p>
                          <span class="notification-time"><span class="me-2" role="img"
                              aria-label="Emoji">🌤️</span>1d</span>

                        </div>
                      </a>

                    </div>
                    <div class="list-group-item">
                      <a class="border-bottom-0 notification-unread  notification notification-flush" href="#!">
                        <div class="notification-avatar">
                          <div class="avatar avatar-xl me-3">
                            <img class="rounded-circle" src="assets/img/logos/oxford.png" alt="" />

                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1"><strong>University of Oxford</strong> created an event : "Causal Inference
                            Hilary 2019"</p>
                          <span class="notification-time"><span class="me-2" role="img"
                              aria-label="Emoji">✌️</span>1w</span>

                        </div>
                      </a>

                    </div>
                    <div class="list-group-item">
                      <a class="border-bottom-0 notification notification-flush" href="#!">
                        <div class="notification-avatar">
                          <div class="avatar avatar-xl me-3">
                            <img class="rounded-circle" src="assets/img/team/10.jpg" alt="" />

                          </div>
                        </div>
                        <div class="notification-body">
                          <p class="mb-1"><strong>James Cameron</strong> invited to join the group: United Nations
                            International Children's Fund</p>
                          <span class="notification-time"><span class="me-2" role="img"
                              aria-label="Emoji">🙋‍</span>2d</span>

                        </div>
                      </a>

                    </div>
                  </div>
                </div>
                <div class="card-footer text-center border-top"><a class="card-link d-block"
                    href="app/social/notifications.html">View all</a></div>
              </div>
            </div>

          </li>-->
          <li class="nav-item dropdown"><a class="nav-link pe-0" id="navbarDropdownUser" href="#" role="button"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="avatar avatar-xl">
                <!--<img class="rounded-circle" src="assets/img/team/3-thumb.png" alt="" />-->
                <div class="avatar-name rounded-circle"><span> {{ user.firstName | slice : 0:1 }}{{ user.lastName |
                    slice : 0:1 }}</span></div>

              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
              <div class="bg-white dark__bg-1000 rounded-2 py-2">
                <!--<a class="dropdown-item" href="/profile">Profile &amp; account</a>-->

                <!--<div class="dropdown-divider"></div>-->
                <!--<a class="dropdown-item" href="/settings">Settings</a>-->
                <a class="dropdown-item" href="/logout">Logout</a>
              </div>
            </div>
          </li>
        </ul>
      </nav>

      <router-outlet></router-outlet>

      <footer>
        <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600"><br class="d-sm-none" /> 2021 &copy; <a href="https://tipeasy.co">Tipeasy</a></p>
          </div>
          <div class="col-12 col-sm-auto text-center">
            <p class="mb-0 text-600">v1.0.0</p>
          </div>
        </div>
      </footer>
    </div>
    <div class="modal fade" id="authentication-modal" tabindex="-1" role="dialog"
      aria-labelledby="authentication-modal-label" aria-hidden="true">
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div class="modal-header px-5 position-relative modal-shape-header bg-shape">
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">Register</h4>
              <p class="fs--1 mb-0 text-white">Please create your free Falcon account</p>
            </div>
            <button class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label>
                <input class="form-control" type="text" id="modal-auth-name" />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email">Email address</label>
                <input class="form-control" type="email" id="modal-auth-email" />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password">Password</label>
                  <input class="form-control" type="password" id="modal-auth-password" />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password">Confirm Password</label>
                  <input class="form-control" type="password" id="modal-auth-confirm-password" />
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="modal-auth-register-checkbox" />
                <label class="form-label" for="modal-auth-register-checkbox">I accept the <a href="#!">terms </a>and <a
                    href="#!">privacy policy</a></label>
              </div>
              <div class="mb-3">
                <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Register</button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr class="bg-300" />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6"><a class="btn btn-outline-google-plus btn-sm d-block w-100" href="#"><span
                    class="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span> google</a></div>
              <div class="col-sm-6"><a class="btn btn-outline-facebook btn-sm d-block w-100" href="#"><span
                    class="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span> facebook</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<!-- ===============================================-->
<!--    End of Main Content-->
<!-- ===============================================-->