import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  Employee,
  EmployeeRegistration,
  ExportBalancesRequest,
  ExportBalancesResponse,
  ExportListResponse,
  GenericResponse,
  PayoutsResponse,
  RegistrationData,
  RegistrationResponse,
  ResponseBalances,
  ResponseEmployees,
  ResponseRestaurants,
  ResponseReviews,
  ResponseTransactions,
  ResponseUrl,
  ResponseUsers,
  Restaurant,
  SaveEmployee,
  SaveRestaurant,
  SortOrder,
  TotalSalesResponse,
  User,
} from '../../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router) {}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(operation + ': ' + error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private filterUndefined(params: any) {
    return Object.keys(params)
      .filter((key) => params[key] !== undefined)
      .reduce((obj: any, key) => {
        obj[key] = params[key];
        return obj;
      }, {});
  }

  public getUsers(
    params: any = {},
    sort: string = '',
    order = SortOrder.DESC,
    page = 1,
    limit = 20
  ) {
    params = this.filterUndefined(params);
    return this.http.get<ResponseUsers>(
      environment.apiUrl + '/api/dashboard/users',
      {
        params: {
          ...params,
          sortBy: sort,
          sortOrder: order,
          page: page,
          limit: limit,
        },
        withCredentials: true,
      }
    );
  }

  public setRestaurantStatus(strid: string, status: string) {
    return this.http.post<Restaurant>(
      environment.apiUrl + '/api/dashboard/restaurantStatus',
      {
        strid,
        status,
      },
      { withCredentials: true }
    );
  }

  public sendTestEmail() {
    return this.http.get<any>(environment.apiUrl + '/api/dashboard/testEmail');
  }

  public getRestaurants(
    params: any = {},
    sort: string = '',
    order = SortOrder.DESC,
    page = 1,
    limit = 20
  ) {
    params = this.filterUndefined(params);
    return this.http.get<ResponseRestaurants>(
      environment.apiUrl + '/api/dashboard/restaurants',
      {
        params: {
          ...params,
          sortBy: sort,
          sortOrder: order,
          page: page,
          limit: limit,
        },
        withCredentials: true,
      }
    );
  }

  public saveRestaurant(restaurant: SaveRestaurant) {
    return this.http.post<boolean>(
      environment.apiUrl + '/api/dashboard/restaurant',
      restaurant
    );
  }

  public getEmployee(guid: string) {
    return this.http.get<Employee>(
      environment.apiUrl + '/api/dashboard/employee',
      {
        params: {
          guid,
        },
        withCredentials: true,
      }
    );
  }

  public getRestaurant(strid: string) {
    return this.http.get<Restaurant>(
      environment.apiUrl + '/api/dashboard/restaurant',
      {
        params: {
          strid,
        },
        withCredentials: true,
      }
    );
  }

  public saveEmployee(employee: SaveEmployee, resendInvitation = false) {
    return this.http.post<boolean>(
      environment.apiUrl + '/api/dashboard/employee',
      {
        employee,
        resendInvitation,
      },
      { withCredentials: true }
    );
  }

  public getEmployees(
    params: any = {},
    sort: string = '',
    order = SortOrder.DESC,
    page = 1,
    limit = 20
  ) {
    params = this.filterUndefined(params);
    return this.http.get<ResponseEmployees>(
      environment.apiUrl + '/api/dashboard/employees',
      {
        params: {
          ...params,
          sortBy: sort,
          sortOrder: order,
          page: page,
          limit: limit,
        },
        withCredentials: true,
      }
    );
  }

  public getWorkplaces(
    params: any = {},
    sort: string = '',
    order = SortOrder.DESC,
    page = 1,
    limit = 20
  ) {
    params = this.filterUndefined(params);
    return this.http.get<ResponseEmployees>(
      environment.apiUrl + '/api/dashboard/workplaces',
      {
        params: {
          ...params,
          sortBy: sort,
          sortOrder: order,
          page: page,
          limit: limit,
        },
        withCredentials: true,
      }
    );
  }

  public getTransactions(
    params: any = {},
    sort: string = '',
    order = SortOrder.DESC,
    page = 1,
    limit = 20
  ) {
    params = this.filterUndefined(params);
    return this.http.get<ResponseTransactions>(
      environment.apiUrl + '/api/dashboard/transactions',
      {
        params: {
          ...params,
          sortBy: sort,
          sortOrder: order,
          page: page,
          limit: limit,
        },
        withCredentials: true,
      }
    );
  }

  public getTotalSales(from: string, strid?: string, guid?: string) {
    return this.http.get<TotalSalesResponse>(
      environment.apiUrl + '/api/dashboard/sales',
      {
        params: {
          from,
          strid: strid ?? '',
          guid: guid ?? '',
        },
        withCredentials: true,
      }
    );
  }

  public getReviews(
    params: any = {},
    sort: string = '',
    order = SortOrder.DESC,
    page = 1,
    limit = 20
  ) {
    params = this.filterUndefined(params);
    return this.http.get<ResponseReviews>(
      environment.apiUrl + '/api/dashboard/reviews',
      {
        params: {
          ...params,
          sortBy: sort,
          sortOrder: order,
          page: page,
          limit: limit,
        },
        withCredentials: true,
      }
    );
  }

  public check(username: string) {
    return this.http.get<boolean>(environment.apiUrl + '/api/check', {
      params: {
        username,
      },
      withCredentials: true,
    });
  }

  public registration(data: RegistrationData) {
    return this.http.post<RegistrationResponse>(
      environment.apiUrl + '/api/registration',
      data,
      {
        withCredentials: true,
      }
    );
  }

  public employeeRegistration(data: EmployeeRegistration) {
    return this.http.post<RegistrationResponse>(
      environment.apiUrl + '/api/employeeRegistration',
      data,
      { withCredentials: true }
    );
  }

  public setEmployeeStatus(guid: string, status: string) {
    return this.http.post<Employee>(
      environment.apiUrl + '/api/dashboard/employeeStatus',
      {
        guid,
        status,
      },
      { withCredentials: true }
    );
  }

  public setUserStatus(id: number, status: string) {
    return this.http.post<User>(
      environment.apiUrl + '/api/dashboard/userStatus',
      {
        id,
        status,
      },
      { withCredentials: true }
    );
  }

  public confirmEmail(token: string) {
    return this.http.get<GenericResponse>(environment.apiUrl + '/api/confirm', {
      params: {
        token,
      },
      withCredentials: true,
    });
  }

  public getBalances(
    params: any = {},
    sort: string = '',
    order = SortOrder.DESC,
    page = 1,
    limit = 20
  ) {
    params = this.filterUndefined(params);
    return this.http.get<ResponseBalances>(
      environment.apiUrl + '/api/dashboard/balances',
      {
        params: {
          ...params,
          sortBy: sort,
          sortOrder: order,
          page: page,
          limit: limit,
        },
        withCredentials: true,
      }
    );
  }

  public exportBalances(params: ExportBalancesRequest) {
    return this.http.post<User>(
      environment.apiUrl + '/api/dashboard/exportBalances',
      params,
      { withCredentials: true }
    );
  }

  public exportList(
    params: any = {},
    sort: string = '',
    order = SortOrder.DESC,
    page = 1,
    limit = 20
  ) {
    params = this.filterUndefined(params);
    return this.http.get<ExportListResponse>(
      environment.apiUrl + '/api/dashboard/exportList',
      {
        params: {
          ...params,
          sortBy: sort,
          sortOrder: order,
          page: page,
          limit: limit,
        },
        withCredentials: true,
      }
    );
  }

  public download(key: string = ''): Observable<HttpResponse<Blob>> {
    return this.http.get(environment.apiUrl + '/api/dashboard/download/', {
      params: {
        key,
      },
      responseType: 'blob',
      observe: 'response',
      withCredentials: true,
    });
  }

  public voidExport(id: number) {
    return this.http.get<ExportBalancesResponse>(
      environment.apiUrl + '/api/dashboard/voidExport',
      {
        params: {
          id: id.toString(),
        },
        withCredentials: true,
      }
    );
  }

  public getPayouts() {
    return this.http.get<PayoutsResponse>(
      environment.apiUrl + '/api/dashboard/payouts',
      {
        withCredentials: true,
      }
    );
  }

  public getOnboardingLink() {
    return this.http.get<ResponseUrl>(
      environment.apiUrl + '/api/dashboard/getOnboardingLink',
      {
        withCredentials: true,
      }
    );
  }
}
