<main class="main dark" id="top">
    <div class="container-fluid">
        <div class="row min-vh-100 bg-100">
            <div class="col-6 d-none d-lg-block position-relative">
                <div class="bg-holder"
                    style="background-image:url(../../../assets/img/generic/login_image.jpg);background-position: 50% 20%;">
                </div>
                <!--/.bg-holder-->
            </div>
            <div class="col-sm-10 col-md-6 px-sm-0 align-self-center mx-auto py-5">
                <div class="row justify-content-center g-0">
                    <div class="col-lg-10 col-xl-9 col-xxl-8">
                        <ng-content></ng-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>