<div class="row">
    <div class="col-12 mb-3">
        <div class="card">
            <div class="card-body">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Transactions</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 mb-3">

        <data-table [tableConfig]="tableConfig" [tableContent]="transactions" [totalItems]="totalItems"
            [itemsPerPage]="itemsPerPage" (onSort)="loadData($event)"></data-table>

    </div>
</div>