<div class="row">
  <div class="col-12 mb-3">
          <div class="card">
                  <div class="card-body">
                          <nav aria-label="breadcrumb">
                                  <ol class="breadcrumb">
                                          <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                                          <li class="breadcrumb-item active" aria-current="page">Payments</li>
                                  </ol>
                          </nav>
                  </div>
          </div>
  </div>
</div>

<div *ngIf="!!stripeLoading">just a test</div>

<div class="card" *ngIf="!payoutsResponse?.features?.account">
  <div class="card-body overflow-hidden p-lg-6">
    <div class="row align-items-center">
      <div class="col-12 my-5 text-center text-lg-center">
        <div *ngIf="payoutsResponse?.result == 'NEEDVERIFY'; else setupStripe">
          <p class="lead">Required information on Stripe is missing or being verified...</p>
          <a class="btn btn-falcon-primary" [ngClass]="stripeLoading ? 'disabled':''" (click)="createConnectedAccount()">Check my account
            <span [ngClass]="stripeLoading ? '':'d-none'"><i class="fa fa-spinner fa-spin"></i></span>
          </a>
        </div>
        <ng-template #setupStripe>
          <p class="lead">To receive direct payment, please register an account with our partner Stripe</p>
          <a class="btn btn-falcon-primary" [ngClass]="stripeLoading ? 'disabled':''" (click)="createConnectedAccount()">Create account
            <span [ngClass]="stripeLoading ? '':'d-none'"><i class="fa fa-spinner fa-spin"></i></span>
          </a>
        </ng-template>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="payoutsResponse?.features?.account">
  <div class="row mb-3">
    <div class="col-12">
      <div class="card">
        <div class="card-body overflow-hidden">
          <div class="row align-items-center">
            <div class="col-12 text-center text-lg-center">
              <p class="lead">
                  To edit your Stripe settings, please go to your
                  <a class="btn btn-falcon-primary" [ngClass]="stripeLoading ? 'disabled':''"  (click)="createConnectedAccount()">Stripe account
                    <span [ngClass]="stripeLoading ? '':'d-none'"><i class="fa fa-spinner fa-spin"></i></span>
                  </a>
              </p>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
            <data-table [tableConfig]="tableConfig" [tableContent]="tableContents" [totalItems]="totalItems"
                    [itemsPerPage]="itemsPerPage" (onSort)="loadData($event)"></data-table>
    </div>
  </div>
</ng-container>