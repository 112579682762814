import { Component, OnInit } from '@angular/core';
import { Restaurant, Role } from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import { TableConfig } from '../../elements/data-table/data-table.interface';
import { ModalService } from '../../modal/modal.service';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-restaurants',
  templateUrl: './restaurants.component.html',
  styleUrls: ['./restaurants.component.scss'],
})
export class RestaurantsComponent implements OnInit {
  restaurants: Restaurant[];
  totalItems: number = 0;
  itemsPerPage = 15;

  private _tableConfig: TableConfig = {
    title: 'Restaurants list',
    headerButtons: [
      { title: 'New', icon: 'fa-plus', link: ['/restaurants', 'edit', 'new'] },
      //{ title: "Filter", icon: "fa-filter" },
      //{ title: "Export", icon: "fa-external-link-alt" }
    ],
    columns: [
      {
        title: 'Name',
        property: 'name',
        sortable: true,
        filterable: false,
        avatar: {
          property: 'logo',
        },
        link: {
          prefix: '/restaurants',
          property: 'strid',
        },
      },
      {
        title: 'Manager',
        property: 'user',
        sortable: true,
        filterable: false,
        visibleTo: [Role.Admin],
        fromObject: true,
        propertyValueFrom: 'firstName',
        link: {
          prefix: '/users',
          property: 'strid',
        },
      },
      { title: 'Phone', property: 'phone', sortable: true, filterable: false },
      { title: 'Email', property: 'email', sortable: true, filterable: false },
      {
        title: 'Added',
        property: 'createdOn',
        sortable: true,
        filterable: false,
        isDate: true,
      },
      {
        title: 'Status',
        property: 'status',
        sortable: true,
        filterable: false,
        badge: {
          active: { class: 'bg-primary', icon: 'fa-check' },
          pending: { class: 'bg-warning', icon: 'fa-stream' },
          onhold: { class: 'bg-secondary', icon: 'fa-times' },
        },
      },
      {
        moreOptions: true,
        options: [
          {
            title: 'Set Active',
            class: 'text-primary',
            showOnlyIfNot: {
              property: 'status',
              value: ['active'],
            },
            click: {
              property: 'strid',
              fn: (strid: string) =>
                this.updateRestaurantStatus(strid, 'active'),
            },
          },
          {
            title: 'Set On-Hold',
            class: 'text-primary',
            showOnlyIfNot: {
              property: 'status',
              value: ['pending', 'onhold'],
            },
            click: {
              property: 'strid',
              fn: (strid: string) =>
                this.updateRestaurantStatus(strid, 'onhold'),
            },
          },

          {
            title: 'Edit',
            link: {
              prefix: '/restaurants/edit',
              property: 'strid',
            },
          },
          /*{
            title: 'Delete',
            class: 'text-danger',
            link: {
              property: 'guid',

            }
          },*/
        ],
      },
    ],
  };

  get tableConfig() {
    return this._tableConfig;
  }

  constructor(
    private apiService: ApiService,
    private modalService: ModalService
  ) {
    this.restaurants = [];
  }

  updateRestaurantStatus(strid: string, status: string) {
    this.apiService.setRestaurantStatus(strid, status).subscribe(
      (response) => {
        const idx = this.restaurants.findIndex((r) => r.strid == strid);
        if (idx > -1) {
          this.restaurants[idx] = {
            ...this.restaurants[idx],
            ...response,
          };
          this.modalService.success('Status updated!');
        }
      },
      () => {
        this.modalService.error('Does not work!');
      }
    );
  }

  loadData(sort?: SortEvent) {
    this.apiService
      .getRestaurants(
        [],
        sort?.sortBy,
        sort?.sortDirection,
        sort?.page,
        this.itemsPerPage
      )
      .subscribe(
        (response) => {
          this.restaurants = response.restaurants;
          this.totalItems = response.total;
        },
        () => {
          console.log('err');
        }
      );
  }

  ngOnInit(): void {
    this.loadData();
  }
}
