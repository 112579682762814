<div class="row">
    <div class="col-12 mb-3">
        <div class="card">
            <div class="card-body">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/restaurants']">Restaurants</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ f.name.value }}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>

<div class="row g-0 h-100 align-items-stretch">
    <div class="col-lg-12">
        <div class="card h-100">
            <div class="card-header">
                <h5 class="mb-0">Restaurant</h5>
            </div>
            <div class="card-body bg-light">
                <form [formGroup]="restaurantForm" (ngSubmit)="onSubmit()" method="POST" action="/">
                    <div class="mb-3">
                        <dropzone [image]="restaurantLogo" [event]="dropZoneEvent" (response)="onResponse($event)"
                            [title]="'Restaurant'" [type]="'logo'" [avatarType]="'rounded-1'"></dropzone>
                    </div>

                    <div class="mb-3"><label class="form-label" for="form-name">Name</label><input class="form-control"
                            type="text" formControlName="name" id="form-name" placeholder="Name"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" id="form-name"></div>
                    <div class="mb-3"><label class="form-label" for="form-address">Address</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }" class="form-control" type="text"
                            formControlName="address" name="address" placeholder="Address" id="form-address">
                    </div>
                    <div class="mb-3"><label class="form-label" for="form-phone">Phone</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" class="form-control" type="text"
                            formControlName="phone" name="phone" placeholder="Phone" id="form-phone"></div>
                    <div class="mb-3"><label class="form-label" for="form-email">E-Mail</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control" type="text"
                            formControlName="email" name="email" placeholder="E-Mail" id="form-email"></div>

                    <div class="mb-3">
                        <div class="mb-3"><label class="form-label" for="form-payment-commission">Commission (restaurant
                                part)
                            </label>
                            <div class="input-group">
                                <div class="input-group-text">%</div>
                                <input class="form-control" formControlName="paymentCommission"
                                    id="form-payment-commission" type="text" placeholder="0.00" />
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="form-check form-switch">
                            <input [ngClass]="{ 'is-invalid': submitted && f.directPayment.errors }"
                                class="form-check-input" formControlName="directPayment" name="directPayment"
                                id="form-direct-payment" type="checkbox" value="1" />
                            <label class="form-check-label" for="form-direct-payment">Allow Tipeasy to do direct payouts
                                to employees</label>
                        </div>
                    </div>

                    <div class="mb-3">
                        <button type="submit" [disabled]="loading"
                            class="btn btn-primary btn-block mt-3 w-lg-25 w-sm-100">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Save
                        </button>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </div>
    </div>
</div>