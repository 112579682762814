import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Balance } from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import {
  TableColumnAlign,
  TableConfig,
} from '../../elements/data-table/data-table.interface';
import { ModalService } from '../../modal/modal.service';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
  balances: Balance[] = [];
  totalItems: number = 0;
  itemsPerPage = 15;
  disabled = '';

  exportForm = this.formBuilder.group({
    reduceBalance: false,
    exportEmployees: true,
    consolidateEmployeeBalances: true,
    exportRestaurants: true,
  });
  submitted = false;

  private _tableConfig: TableConfig = {
    title: 'Balance list',
    headerButtons: [
      //{ title: 'New', icon: 'fa-plus', link: ['/restaurants', 'edit', 'new'] },
      { title: 'Filter', icon: 'fa-filter' },
      {
        title: 'Export',
        icon: 'fa-external-link-alt',
        click: {
          fn: () => this.modalService.popup('exportModal'),
        },
      },
    ],
    columns: [
      {
        title: 'Type',
        property: 'entityType',
        sortable: true,
        filterable: true,
        badge: {
          restaurant: { class: 'bg-primary', text: 'restaurant' },
          employee: { class: 'bg-warning', text: 'employee' },
          user: { class: 'bg-info', text: 'user' },
          tipeasy: { class: 'bg-secondary', text: 'TipEasy' },
        },
        align: TableColumnAlign.CENTER,
      },
      {
        title: 'Recipient',
        property: 'name',
        sortable: true,
        filterable: true,
        avatar: {
          property: 'image',
        },
        link: (item: Balance) => {
          switch (item.entityType) {
            case 'restaurant':
            case 'employee':
              return '/' + item.entityType + 's/' + item.strid;
            case 'tipeasy':
              return '/admin/income';
          }
          return null;
        },
      },
      { title: 'Bank', property: 'bankName', sortable: true, filterable: true },
      {
        title: 'IBAN',
        property: 'bankIban',
        sortable: false,
        filterable: true,
      },
      {
        title: 'Balance',
        property: 'balance',
        sortable: true,
        filterable: true,
        isMoney: true,
        align: TableColumnAlign.RIGHT,
      },
      {
        title: 'Pending',
        property: 'balancePending',
        sortable: true,
        filterable: true,
        isMoney: true,
        align: TableColumnAlign.RIGHT,
      },
      {
        moreOptions: true,
        align: TableColumnAlign.RIGHT,
        options: [
          {
            title: 'Modify balance',
            class: 'text-primary',
            /*showOnlyIfNot: {
              property: 'status',
              value: ['active'],
            },*/
            /*link: {
              property: 'strid',
              fn: (strid: string) =>
                this.updateRestaurantStatus(strid, 'active'),
            },*/
          },
          {
            title: 'Details',
            link: (item: Balance) => {
              switch (item.entityType) {
                case 'restaurant':
                case 'employee':
                  return '/balance/' + item.entityType + 's/' + item.strid;
                case 'tipeasy':
                  return '/admin/income';
              }
              return null;
            },
          },
          /*{
            title: 'Delete',
            class: 'text-danger',
            link: {
              property: 'guid',

            }
          },*/
        ],
      },
    ],
  };

  get tableConfig() {
    return this._tableConfig;
  }

  get f() {
    return this.exportForm?.controls ?? {};
  }

  exportEmployeesChange(event: any) {
    const input = event?.target as HTMLInputElement;
    const consolidateEmployeeBalancesControl = this.exportForm.get(
      'consolidateEmployeeBalances'
    );
    if (input.checked) {
      consolidateEmployeeBalancesControl?.enable();
    } else {
      consolidateEmployeeBalancesControl?.disable();
    }
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.exportForm?.invalid) {
      return;
    }

    this.apiService
      .exportBalances(this.exportForm.getRawValue())
      .subscribe((response) => {
        this.router.navigate(['/export']);
      });
  }

  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    //
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(sort?: SortEvent) {
    this.apiService
      .getBalances(
        [],
        sort?.sortBy,
        sort?.sortDirection,
        sort?.page,
        this.itemsPerPage
      )
      .subscribe(
        (response) => {
          this.balances = response.balances;
          this.totalItems = response.total;
        },
        () => {
          console.log('err');
        }
      );
  }
}
