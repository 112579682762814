import { Component, OnInit } from '@angular/core';
import { Payout, PayoutsResponse } from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import {
  TableColumnAlign,
  TableConfig,
} from '../../elements/data-table/data-table.interface';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  payoutsResponse: PayoutsResponse;

  tableContents: Payout[] = [];
  totalItems = 0;
  itemsPerPage = 15;

  stripeLoading = false;

  error = '';

  constructor(private apiService: ApiService) {
    // set defaults
    this.payoutsResponse = {
      result: 'NEEDSETUP',
      payouts: {
        payout: [],
        total: 0,
      },
      features: {
        account: false,
        sepa: false,
        transfers: false,
        payouts: false,
      },
      message: '',
    };
  }

  private _tableConfig: TableConfig = {
    title: 'Payouts',
    headerButtons: [],
    columns: [
      {
        title: 'Paid On',
        property: 'createdOn',
        sortable: true,
        filterable: false,
        isDate: true,
        isTime: true,
      },
      {
        title: 'Expected On',
        property: 'expectedOn',
        sortable: true,
        filterable: false,
        isDate: true,
      },
      {
        title: 'Amount',
        property: 'amount',
        sortable: true,
        filterable: true,
        isMoney: true,
        align: TableColumnAlign.RIGHT,
      },
      {
        title: 'Status',
        property: 'status',
        sortable: true,
        filterable: true,
        align: TableColumnAlign.CENTER,
        badge: {
          failed: { class: 'bg-danger', icon: 'fa-times' },
          paid: { class: 'bg-success', icon: 'fa-check' },
          pending: { class: 'bg-secondary', text: 'pending' },
          in_transit: { class: 'bg-secondary', text: 'in transit' },
          canceled: { class: 'bg-danger', icon: 'fa-ban' },
        },
      },
      {
        title: 'Message',
        property: 'error',
        sortable: false,
        filterable: false,
        wrap: true,
      },
    ],
  };

  get tableConfig() {
    return this._tableConfig;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(sort?: SortEvent) {
    this.apiService.getPayouts().subscribe(
      (response) => {
        Object.assign(this.payoutsResponse, response);

        this.tableContents = this.payoutsResponse.payouts?.payout ?? [];
        this.totalItems = this.payoutsResponse.payouts?.total ?? 0;
      },
      () => {
        console.log('err');
      }
    );
  }

  async createConnectedAccount() {
    this.stripeLoading = true;

    this.apiService.getOnboardingLink().subscribe(
      (response) => {
        window.location.href = response.url ?? '';
      },
      (err) => {
        this.stripeLoading = false;
        this.error =
          'There was an error loading your stripe account. Please try again later...';
      }
    );
  }
}
