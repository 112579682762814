<div class="modal fade" id="error-modal" tabindex="-1" style="padding-right: 17px;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document" [style.max-width]="type=='popup' ? null : '400px'">
        <div class="modal-content position-relative" [class.p-5]="type!='popup'">
            <ng-container *ngIf="type=='popup'">
                <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1"><button
                        class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                        data-bs-dismiss="modal" aria-label="Close"></button></div>
                <ng-content></ng-content>
            </ng-container>

            <ng-container *ngIf="type!='popup'">
                <div class="d-flex align-items-center">
                    <ng-lottie class="lottie" [options]="lottieOptions"></ng-lottie>
                    <div class="flex-1">
                        <button (click)="close()"
                            class="btn btn-link text-danger position-absolute top-0 end-0 mt-2 me-2"
                            data-bs-dismiss="modal">
                            <svg class="svg-inline--fa fa-times fa-w-11" aria-hidden="true" focusable="false"
                                data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 352 512" data-fa-i2svg="">
                                <path fill="currentColor"
                                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                </path>
                            </svg><!-- <span class="fas fa-times"></span> Font Awesome fontawesome.com --></button>
                        <p class="mb-0">{{ content }}</p>
                        <div *ngIf="type=='confirmation' && callback != undefined">
                            <button (click)="callback(); close()" class="btn btn-danger m-2">Yes</button>
                            <button (click)="close()" class="btn btn-secondary m-2">No</button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>