import { Component, OnInit } from '@angular/core';
import { Transaction } from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import { TableConfig } from '../../elements/data-table/data-table.interface';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  transactions: Transaction[];
  totalItems: number = 0;
  itemsPerPage = 15;

  private _tableConfig: TableConfig = {
    title: 'Transaction list',
    headerButtons: [
      { title: 'Filter', icon: 'fa-filter' },
      { title: 'Export', icon: 'fa-external-link-alt' },
    ],
    columns: [
      { title: 'ID', property: 'id', sortable: true, filterable: false },
      /*{
        title: 'Transaction Id',
        property: 'transactionId',
        sortable: false,
        filterable: false,
      },*/
      {
        title: 'Type',
        property: 'type',
        sortable: false,
        filterable: false,
        badge: {
          apple_pay_card: { class: 'bg-primary', text: 'apple' },
          android_pay_card: { class: 'bg-warning', text: 'google pay' },
          credit_card: { class: 'bg-success', text: 'card' },
        },
      },
      {
        title: 'Amount',
        property: 'amount',
        sortable: true,
        filterable: false,
      },
      {
        title: 'Currency',
        property: 'currency',
        sortable: false,
        filterable: false,
      },
      {
        title: 'Employee',
        property: 'employee',
        sortable: false,
        filterable: false,
        fromObject: true,
        useFirstLastName: true,
        link: {
          prefix: '/employees',
          property: 'guid',
        },
      },
      {
        title: 'Restaurant',
        property: 'restaurant',
        sortable: false,
        filterable: false,
        fromObject: true,
        link: {
          prefix: '/restaurant',
          property: 'strid',
        },
      },
      {
        title: 'Created',
        property: 'createdOn',
        sortable: true,
        filterable: false,
        isDate: true,
        isTime: true,
      },
      {
        title: 'Status',
        property: 'status',
        sortable: true,
        filterable: false,
        badge: {
          complete: { class: 'bg-primary', icon: 'fa-check' },
          pending: { class: 'bg-warning', icon: 'fa-stream' },
          failed: { class: 'bg-danger', icon: 'fa-times' },
          refund: { class: 'bg-secondary', icon: 'fa-ban' },
        },
        tooltip: {
          property: 'error',
        },
      },
    ],
  };

  get tableConfig() {
    return this._tableConfig;
  }

  constructor(private apiService: ApiService) {
    this.transactions = [];
  }

  loadData(sort?: SortEvent) {
    this.apiService
      .getTransactions(
        [],
        sort?.sortBy,
        sort?.sortDirection,
        sort?.page,
        this.itemsPerPage
      )
      .subscribe(
        (response) => {
          this.transactions = response.transactions;
          this.totalItems = response.total;
        },
        () => {
          console.log('err');
        }
      );
  }

  ngOnInit(): void {
    this.loadData();
  }
}
