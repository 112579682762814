import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CountUpModule } from 'ngx-countup';
import { ElementsModule } from '../elements/elements.module';
import { ModalModule } from '../modal/modal.module';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { BalanceComponent } from './balance/balance.component';
import { DetailsComponent } from './balance/details/details.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard.component';
import { EmployeeEditComponent } from './employees/edit/edit.component';
import { EmployeeComponent } from './employees/employee/employee.component';
import { EmployeesComponent } from './employees/employees.component';
import { FaqComponent } from './faq/faq.component';
import { RestaurantEditComponent } from './restaurants/edit/edit.component';
import { RestaurantComponent } from './restaurants/restaurant/restaurant.component';
import { RestaurantsComponent } from './restaurants/restaurants.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { UserComponent } from './users/user/user.component';
import { UsersComponent } from './users/users.component';
import { WorkplacesComponent } from './workplaces/workplaces.component';
import { ExportComponent } from './export/export.component';
import { PaymentsComponent } from './payments/payments.component';

@NgModule({
  declarations: [
    DashboardComponent,
    StatisticsComponent,
    RestaurantsComponent,
    EmployeesComponent,
    ReviewsComponent,
    AdminSettingsComponent,
    ContactComponent,
    FaqComponent,
    TransactionsComponent,
    EmployeeComponent,
    RestaurantComponent,
    UsersComponent,
    UserComponent,
    EmployeeEditComponent,
    RestaurantEditComponent,
    WorkplacesComponent,
    BalanceComponent,
    DetailsComponent,
    ExportComponent,
    PaymentsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ElementsModule,
    CountUpModule,
    ReactiveFormsModule,
    ModalModule,
  ],
})
export class DashboardModule {}
