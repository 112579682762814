<h2 class="pb-4">FAQ / Frequently Asked Questions</h2>

<div class="card">
  <div class="card-body">
    <h5 class="pb-1">General</h5>
    <div class="accordion border-x border-top rounded" id="accordionFaq">
      <div class="card shadow-none border-bottom rounded-bottom-0">
        <div class="card-header p-0" id="faqAccordionHeading1"><button
            class="accordion-button btn btn-link text-decoration-none d-block w-100 py-2 px-3 collapsed border-0 text-start"
            data-bs-toggle="collapse" data-bs-target="#collapseFaqAccordion1" aria-expanded="false"
            aria-controls="collapseFaqAccordion1"><span class="fas fa-caret-right accordion-icon me-3"
              data-fa-transform="shrink-2"></span><span class="fw-medium font-sans-serif text-900">How long do payouts
              take?</span></button></div>
        <div class="collapse bg-light" id="collapseFaqAccordion1" aria-labelledby="faqAccordionHeading1"
          data-parent="#accordionFaq">
          <div class="card-body">
            <p class="ps-4 mb-0">Once you’re set up, payouts arrive in your bank account on a 2-day rolling basis. Or
              you can opt to receive payouts weekly or monthly.</p>
          </div>
        </div>
      </div>
      <div class="card shadow-none border-bottom rounded-0">
        <div class="card-header p-0" id="faqAccordionHeading2"><button
            class="accordion-button btn btn-link text-decoration-none d-block w-100 py-2 px-3 collapsed border-0 text-start"
            data-bs-toggle="collapse" data-bs-target="#collapseFaqAccordion2" aria-expanded="false"
            aria-controls="collapseFaqAccordion2"><span class="fas fa-caret-right accordion-icon me-3"
              data-fa-transform="shrink-2"></span><span class="fw-medium font-sans-serif text-900">How do refunds
              work?</span></button></div>
        <div class="collapse bg-light" id="collapseFaqAccordion2" aria-labelledby="faqAccordionHeading2"
          data-parent="#accordionFaq">
          <div class="card-body">
            <p class="ps-4 mb-0">You can issue either partial or full refunds. There are no fees to refund a charge, but
              the fees from the original charge are not returned.</p>
          </div>
        </div>
      </div>
      <!--</div>

    <h5 class="pt-4 pb-1">Darbības ar darbiniekiem</h5>
    <div class="accordion border-x border-top rounded" id="accordionFaq">-->
      <div class="card shadow-none border-bottom rounded-0">
        <div class="card-header p-0" id="faqAccordionHeading3"><button
            class="accordion-button btn btn-link text-decoration-none d-block w-100 py-2 px-3 collapsed border-0 text-start"
            data-bs-toggle="collapse" data-bs-target="#collapseFaqAccordion3" aria-expanded="false"
            aria-controls="collapseFaqAccordion3"><span class="fas fa-caret-right accordion-icon me-3"
              data-fa-transform="shrink-2"></span><span class="fw-medium font-sans-serif text-900">How much do disputes
              costs?</span></button></div>
        <div class="collapse bg-light" id="collapseFaqAccordion3" aria-labelledby="faqAccordionHeading3"
          data-parent="#accordionFaq">
          <div class="card-body">
            <p class="ps-4 mb-0">Disputed payments (also known as chargebacks) incur a $15.00 fee. If the customer’s
              bank resolves the dispute in your favor, the fee is fully refunded.</p>
          </div>
        </div>
      </div>
      <div class="card shadow-none border-bottom">
        <div class="card-header p-0" id="faqAccordionHeading4"><button
            class="accordion-button btn btn-link text-decoration-none d-block w-100 py-2 px-3 collapsed border-0 text-start"
            data-bs-toggle="collapse" data-bs-target="#collapseFaqAccordion4" aria-expanded="false"
            aria-controls="collapseFaqAccordion4"><span class="fas fa-caret-right accordion-icon me-3"
              data-fa-transform="shrink-2"></span><span class="fw-medium font-sans-serif text-900">Is there a fee to use
              Apple Pay or Google Pay?</span></button></div>
        <div class="collapse bg-light" id="collapseFaqAccordion4" aria-labelledby="faqAccordionHeading4"
          data-parent="#accordionFaq">
          <div class="card-body">
            <p class="ps-4 mb-0">There are no additional fees for using our mobile SDKs or to accept payments using
              consumer wallets like Apple Pay or Google Pay.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>