<pre-login>
    <div class="card">
        <div class="card-header bg-circle-shape bg-shape text-center p-2">
            <a class="font-sans-serif fw-bolder fs-4 z-index-1 position-relative link-light light" href="/">TipEasy</a>
        </div>
        <div class="card-body p-4">
            <div class="row flex-between-center">
                <div class="col-auto">
                    <h3>Login</h3>
                </div>
                <div class="col-auto fs--1 text-600"><span class="mb-0 fw-semi-bold">New User?</span>
                    &nbsp;<span><a [routerLink]="['/register']">Create account</a></span></div>
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="mb-3"><label class="form-label" for="split-login-email">Email address</label>
                    <input type="text" formControlName="username" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                </div>
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
                <div class="mb-3">
                    <div class="d-flex justify-content-between"><label class="form-label"
                            for="split-login-password">Password</label>
                        <a class="fs--1" [routerLink]="['/forgot-password']">Forgot
                            Password?</a>
                    </div>
                    <input type="password" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <div class="form-check mb-0"><input class="form-check-input" type="checkbox"
                        formControlName="remember"
                        id="remember" /><label class="form-check-label" for="remember">Remember me</label>
                </div>
                <div class="mb-3">
                    <button [disabled]="loading" class="btn btn-primary d-block w-100 mt-3">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Log in
                    </button>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </div>
            </form>
            <!--
                <div class="position-relative mt-4">
                    <hr class="bg-300" />
                    <div class="divider-content-center">or log in with</div>
                </div>
                <div class="row g-2 mt-2">
                    <div class="col-sm-6"><a class="btn btn-outline-google-plus btn-sm d-block w-100" href="#"><span class="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span> google</a></div>
                    <div class="col-sm-6"><a class="btn btn-outline-facebook btn-sm d-block w-100" href="#"><span class="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span> facebook</a></div>
                </div>
                -->
        </div>
    </div>
</pre-login>