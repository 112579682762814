<pre-login>
  <a class="d-flex flex-center mb-4" href="/"><span
      class="font-sans-serif fw-bolder fs-4 d-inline-block">TipEasy</span></a>

  <div class="theme-wizard tab-pane text-center px-sm-3 px-md-5" role="tabpanel" aria-labelledby="wizard-tab4"
    id="wizard-tab4">
    <div *ngIf="done && !error">
      <div class="wizard-lottie-wrapper">
        <div class="wizard-lottie mx-auto my-3">
          <ng-lottie [options]="lottieCelebration"></ng-lottie>
        </div>
      </div>
      <h4 class="mb-1">Your email has been confirmed!</h4>
      <p>For any questions please call our office (9:00 - 17:00, Mon - Fri)<br>+371 26573635</p>

      <button [routerLink]="['/']" class="btn btn-primary px-5 px-sm-6">
        Return to login page</button>
    </div>
    <div *ngIf="done && error">
      <h4 class="mb-1 alert alert-danger">Email confirmation error!</h4>
      <p>Token not found or has been expired!</p>
      <p>For any questions please call our office (9:00 - 17:00, Mon - Fri)<br>+371 26573635</p>

      <button [routerLink]="['/']" class="btn btn-primary px-5 px-sm-6">
        Return to login page</button>
    </div>
    <div *ngIf="!done">
      <ng-lottie [options]="lottieLoading"></ng-lottie>
    </div>
  </div>

</pre-login>