<div class="row dz-clickable" data-dropzone="data-dropzone">
    <div class="col-md-auto">
        <div class="dz-preview dz-preview-single">
            <div
                class="dz-preview-cover d-flex align-items-center justify-content-center mb-3 mb-md-0 dz-image-preview">
                <div class="avatar avatar-4xl max-width-200"><img class="{{ avatarType }}" src="{{ image }}"
                        alt="{{ image }}" id="image" data-dz-thumbnail="data-dz-thumbnail"></div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress=""></span></div>
            </div>
        </div>
    </div>
    <div class="col-md">
        <div class="dz-message dropzone-area px-2 py-3" data-dz-message="data-dz-message">
            <div class="text-center"><img class="me-2" src="assets/img/icons/cloud-upload.svg" alt="" width="25">Upload
                your profile picture<p class="mb-0 fs--1 text-400">
                    Upload a 300x300 jpg image with <br>a maximum size of 400KB</p>
            </div>
        </div>
    </div>
</div>