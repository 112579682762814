import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AnimationOptions } from 'ngx-lottie';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RegistrationData } from '../../../interfaces/api.interface';
import { ModalService } from '../../modal/modal.service';
import { WindowRef } from '../../_helpers/window-ref';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  steps = [
    {
      title: 'Account',
      icon: 'fa-lock',
      tab: '#wizard-tab1',
    },
    {
      title: 'Profile',
      icon: 'fa-user',
      tab: '#wizard-tab2',
    },
    {
      title: 'Done',
      icon: 'fa-thumbs-up',
      tab: '#wizard-tab3',
    },
  ];
  stepsComplete = 0;
  currentStep = 0;
  wizard: HTMLElement | undefined;
  tabToggleButtonEl: NodeListOf<HTMLElement> | undefined;
  formGroups: FormGroup[] = [
    this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        username: [
          '',
          [Validators.required, Validators.email],
          [this.checkUsername.bind(this)],
        ],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
      },
      {
        validators: [this.checkPasswords, this.resetValidation.bind(this)],
      }
    ),
    this.formBuilder.group(
      {
        companyName: ['', Validators.required],
        companyRegistrationNumber: ['', Validators.required],
        phone: ['', Validators.required],
      },
      {
        validators: [this.resetValidation.bind(this)],
      }
    ),
  ];
  validation = false;
  submitted = false;
  totalTabs = 0;
  done = false;

  lottieOptions: AnimationOptions = {
    path: '../../assets/img/animated-icons/celebration.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
  };

  constructor(
    private winref: WindowRef,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private modalService: ModalService
  ) {}

  get f() {
    return this?.formGroups?.[this.currentStep]?.controls;
  }

  ngOnInit(): void {}

  private getTabs(): NodeListOf<HTMLElement> {
    const wizard = <HTMLElement>document.querySelector('.theme-wizard');
    return <NodeListOf<HTMLElement>>(
      wizard.querySelectorAll('[data-wizard-step]')
    );
  }

  checkUsername(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }

    if (control.value.length > 3) {
      return timer(500).pipe(
        switchMap(() =>
          this.apiService.check(control.value).pipe(
            map((response) => {
              if (response) {
                control.setErrors({ taken: true });
                return { taken: true };
              } else {
                return null;
              }
            })
          )
        )
      );
    }

    return of(null);
  }

  resetValidation(control: AbstractControl): ValidationErrors | null {
    this.validation = false;
    return null;
  }

  checkPasswords(control: AbstractControl): ValidationErrors | null {
    if (control) {
      // here we have the 'passwords' group
      const password = <string>control.get('password')?.value;
      const confirmPassword = control.get('confirmPassword')?.value;
      if (password.length == 0) {
        control.get('password')?.setErrors({ required: true });
        return { required: true };
      }
      if (password.length < 6) {
        control.get('password')?.setErrors({ tooShort: true });
        return { tooShort: true };
      }
      if (password !== confirmPassword) {
        control.get('password')?.setErrors({ notSame: true });
        control.get('confirmPassword')?.setErrors({ notSame: true });
        return { notSame: true };
      } else {
        control.get('password')?.setErrors(null);
        control.get('confirmPassword')?.setErrors(null);
      }
    }
    return null;
  }

  private showTab(step: number, validateCurrentStep = false) {
    this.validation = false;
    const tabs = this.getTabs();
    this.totalTabs = tabs.length;
    if (step < 0 || step > tabs.length) {
      return;
    }
    if (validateCurrentStep && this.formGroups?.[this.currentStep]?.invalid) {
      this.validation = true;
      return;
    }

    if (step == tabs.length) {
      try {
        const data: RegistrationData = {
          firstName: this.formGroups[0].controls.firstName.value,
          lastName: this.formGroups[0].controls.lastName.value,
          username: this.formGroups[0].controls.username.value,
          password: this.formGroups[0].controls.password.value,
          companyName: this.formGroups[1].controls.companyName.value,
          companyRegistrationNumber:
            this.formGroups[1].controls.companyRegistrationNumber.value,
          phone: this.formGroups[1].controls.phone.value,
        };
        this.apiService.registration(data).subscribe(
          (response) => {
            if (response.result === 'OK') {
              this.done = true;
            } else {
              this.modalService.error(
                'Error registering account: ' + response.message
              );
            }
          },
          (err) => {
            console.log(err);
            this.modalService.error('Server error, please try again later!');
          }
        );
      } catch (e) {}
      return;
    }

    this.currentStep = step;

    const tab = new this.winref.nativeWindow.bootstrap.Tab(tabs[step]);
    if (tab) {
      tab.show();
    }
  }

  changeStep(step: number) {
    const tabs = this.getTabs();
    if (step < 0 || step > tabs.length - 1) {
      return;
    }
    this.showTab(step);
  }

  nextStep() {
    this.showTab(this.currentStep + 1, true);
  }

  prevStep() {
    this.showTab(this.currentStep - 1);
  }
}
