import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ResponseClientToken, User } from '../../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userSubject = new BehaviorSubject<User | null>(null);
  public user: Observable<User | null> | undefined;

  constructor(private router: Router, private http: HttpClient) {
    const jwtToken =
      sessionStorage.getItem('token') ?? localStorage.getItem('token') ?? '';
    if (jwtToken) {
      try {
        const decodedToken = jwt_decode(jwtToken) as any;
        if (typeof decodedToken === 'object' && decodedToken?.user) {
          const user: User = decodedToken.user;
          this.userSubject = new BehaviorSubject<User | null>(user);
          this.user = this.userSubject.asObservable();
        }
      } catch (e) {}
    }
  }

  public get userValue(): User | null {
    return this.userSubject.value;
  }

  login(username: string, password: string, remember = false) {
    return this.http
      .post<ResponseClientToken>(
        environment.apiUrl + `/api/authenticate`,
        {
          username,
          password,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(
        map((jwtToken) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (remember) {
            localStorage.setItem('token', jwtToken.token);
          } else {
            sessionStorage.setItem('token', jwtToken.token);
          }
          const decodedToken = jwt_decode(jwtToken.token) as any;
          if (typeof decodedToken === 'object' && decodedToken?.user) {
            const user: User = decodedToken.user;
            this.userSubject.next(user);
            return user;
          } else {
            throw new Error('Error logging in');
          }
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }
}
