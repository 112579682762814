import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BadgeComponent } from './badge/badge.component';
import { ChartComponent } from './chart/chart.component';
import { DataTableComponent } from './data-table/data-table.component';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { LottieComponent } from './lottie/lottie.component';

@NgModule({
  declarations: [
    DataTableComponent,
    BadgeComponent,
    ChartComponent,
    DropzoneComponent,
    LottieComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    DataTableComponent,
    BadgeComponent,
    ChartComponent,
    DropzoneComponent,
    LottieComponent,
  ],
})
export class ElementsModule {}
