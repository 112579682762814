import { Component, Input, OnInit } from '@angular/core';
import { WindowRef } from '../../_helpers/window-ref';

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input()
  public class: string = "";

  @Input()
  public text: string = "";

  @Input()
  public icon: string = "";

  @Input()
  public tooltipTitle: string = "";

  @Input()
  public tooltipPosition: string = "left";

  constructor(private windowRef: WindowRef) { }

  ngOnInit(): void {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return new this.windowRef.nativeWindow.bootstrap.Tooltip(tooltipTriggerEl, {
        trigger: 'hover'
      });
    });
  }

}
