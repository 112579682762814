<div class="row">
        <div class="col-12 mb-3">
                <div class="card">
                        <div class="card-body">
                                <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb">
                                                <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Balance</li>
                                        </ol>
                                </nav>
                        </div>
                </div>
        </div>
</div>

<div class="row">
        <div class="col-12">
                <data-table [tableConfig]="tableConfig" [tableContent]="balances" [totalItems]="totalItems"
                        [itemsPerPage]="itemsPerPage" (onSort)="loadData($event)"></data-table>
        </div>
</div>

<tipeasy-modal [id]="'exportModal'">
        <form [formGroup]="exportForm" (ngSubmit)="onSubmit()" method="POST" action="/">
                <div class="modal-body p-0">
                        <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                                <h4 class="mb-1" id="staticBackdropLabel">Export balances</h4>
                                <!--<p class="fs--2 mb-0">Added by <a class="link-600 fw-semi-bold" href="#!">Antony</a></p>-->
                        </div>
                        <div class="p-4">
                                <div class="form-check"><input class="form-check-input" id="flexCheckChecked1"
                                                type="checkbox" value="" formControlName="exportEmployees"
                                                (change)="exportEmployeesChange($event)" /><label
                                                class="form-check-label" for="flexCheckChecked1">Export employee
                                                balances</label></div>
                                <div class="form-check"><input class="form-check-input" id="flexCheckChecked2"
                                                type="checkbox" value=""
                                                formControlName="consolidateEmployeeBalances" /><label
                                                class="form-check-label" for="flexCheckChecked2">Consolidate employee
                                                balances without Bank
                                                details and add to Restaurant balance
                                        </label></div>
                                <div class="form-check"><input class="form-check-input" id="flexCheckChecked3"
                                                type="checkbox" value="" formControlName="exportRestaurants" /><label
                                                class="form-check-label" for="flexCheckChecked3">Export restaurant
                                                balances</label></div>

                                <div class="form-check"><input class="form-check-input" id="flexCheckDefault4"
                                                type="checkbox" value="" formControlName="reduceBalance" /><label
                                                class="form-check-label" for="flexCheckDefault4">Export for bookkeeping
                                                (will reduce balances and export only recipients with valid bank
                                                details)</label></div>
                        </div>
                </div>
                <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn btn-primary" type="submit">Export</button>
                </div>
        </form>

</tipeasy-modal>