import { Component, OnInit } from '@angular/core';
import { User } from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import { TableConfig } from '../../elements/data-table/data-table.interface';
import { ModalService } from '../../modal/modal.service';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: User[];
  totalItems: number = 0;
  itemsPerPage = 15;

  private _tableConfig: TableConfig = {
    title: 'Users list',
    headerButtons: [
      /*{ title: 'New', icon: 'fa-plus', link: ['/users/new'] },
      { title: 'Filter', icon: 'fa-filter' },
      { title: 'Export', icon: 'fa-external-link-alt' },*/
    ],
    columns: [
      {
        title: 'Name',
        property: 'name',
        sortable: true,
        filterable: false,
        avatar: {
          property: 'image',
        },
        link: {
          prefix: '/users',
          property: 'id',
        },
      },
      {
        title: 'Email',
        property: 'username',
        sortable: true,
        filterable: false,
      },
      { title: 'Phone', property: 'phone', sortable: true, filterable: false },
      { title: 'Role', property: 'role', sortable: true, filterable: false },
      {
        title: 'Joined',
        property: 'createdOn',
        sortable: true,
        filterable: false,
        isDate: true,
      },
      {
        title: 'Status',
        property: 'status',
        sortable: true,
        filterable: false,
        badge: {
          active: { class: 'bg-primary', icon: 'fa-check' },
          pending: { class: 'bg-warning', icon: 'fa-stream' },
          onhold: { class: 'bg-secondary', icon: 'fa-times' },
        },
      },
      {
        moreOptions: true,
        options: [
          {
            title: 'Set Active',
            class: 'text-primary',
            showOnlyIfNot: {
              property: 'status',
              value: ['active'],
            },
            click: {
              property: 'id',
              fn: (id: number) => this.updateUserStatus(id, 'active'),
            },
          },
          {
            title: 'Set On-Hold',
            class: 'text-primary',
            showOnlyIfNot: {
              property: 'status',
              value: ['pending', 'onhold'],
            },
            click: {
              property: 'id',
              fn: (id: number) => this.updateUserStatus(id, 'onhold'),
            },
          },

          /*{
            title: 'Edit',
            link: {
              prefix: '/users/edit',
              property: 'id',
            },
          },*/
          /*{
            title: 'Delete',
            class: 'text-danger',
            link: {
              property: 'guid',

            }
          },*/
        ],
      },
    ],
  };

  get tableConfig() {
    return this._tableConfig;
  }

  constructor(
    private apiService: ApiService,
    private modalService: ModalService
  ) {
    this.users = [];
  }

  updateUserStatus(id: number, status: string) {
    this.apiService.setUserStatus(id, status).subscribe(
      (response) => {
        const idx = this.users.findIndex((u) => u.id == id);
        if (idx > -1) {
          this.users[idx] = {
            ...this.users[idx],
            ...response,
          };
          this.modalService.success('Status updated!');
        }
      },
      () => {
        this.modalService.error('Does not work!');
      }
    );
  }

  loadData(sort?: SortEvent) {
    this.apiService
      .getUsers(
        [],
        sort?.sortBy,
        sort?.sortDirection,
        sort?.page,
        this.itemsPerPage
      )
      .subscribe(
        (response) => {
          this.users = response.users;
          this.totalItems = response.total;
        },
        () => {
          console.log('err');
        }
      );
  }

  ngOnInit(): void {
    this.loadData();
  }
}
