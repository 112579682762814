<div class="row mb-3">
  <div class="col">
    <div class="restaurant-logo">
      <img src="{{ restaurant?.logo }}">
    </div>

    <div class="m-3 restaurant-name">
      {{ restaurant?.name }}
    </div>
  </div>

  <div class="col-auto restaurant-buttons">
    <button class="btn btn-outline-primary btn-sm" type="button" (click)="downloadQrCode()">
      <span class="fas fa-download" data-fa-transform="shrink-3 down-2"></span>
      <span class="d-none d-sm-inline-block ms-1">Download QR code</span>
    </button>

    <button class="btn btn-outline-primary btn-sm mx-2" type="button"
      [routerLink]="['/restaurants', 'edit', restaurant?.strid]"
      >
      <span class="fas fa-edit" data-fa-transform="shrink-3 down-2"></span>
      <span class="d-none d-sm-inline-block ms-1">Profile info</span>
    </button>
  </div>
</div>

<div class="row g-0">
  <div class="col-lg-12 mb-3">
    <chart [chartSelector]="months" [chart]="chart.asObservable()" (onSelectorChange)="onChartPeriodChange($event)">
    </chart>
  </div>
</div>

<div class="row g-3 mb-3">
  <div class="col-sm-6 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-1.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Total tips</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning" [countUp]="totalTips"
          [options]="optsMoney">0
        </div><a class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See all<span
            class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-2.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Average tip</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info" [countUp]="averageTip"
          [options]="optsMoney">0
        </div><a class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See all<span
            class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-3.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Tip count</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info" [countUp]="tipCount" [options]="opts">0
        </div><a class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See all<span
            class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>
</div>

<div class="row g-3 mb-3">
  <div class="col-sm-12">
    <reviews *ngIf="restaurant !== undefined" [latestOnly]=true [restaurantStrid]="restaurant?.strid"></reviews>
  </div>
</div>

<div class="row g-3 mb-3">
  <div class="col-sm-12">
    <employees *ngIf="restaurant !== undefined" [restaurantStrid]="restaurant?.strid"></employees>
  </div>
</div>

<img id="qr" class="d-none">