import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AnimationOptions } from 'ngx-lottie';
import { map } from 'rxjs/operators';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  lottieLoading: AnimationOptions = {
    path: '../../assets/img/animated-icons/loading-balls.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
  };
  lottieCelebration: AnimationOptions = {
    path: '../../assets/img/animated-icons/celebration.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
  };

  done = false;
  error = false;
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const token = this.route.snapshot.queryParams['token'] || '';
    const user = jwt_decode<any>(token);
    const timeNow = Math.floor(new Date().getTime() / 1000);
    if (!user || timeNow > user?.exp) {
      this.router.navigateByUrl('/');
      return;
    }

    this.apiService
      .confirmEmail(token)
      .pipe(
        map((response) => {
          this.done = true;
          if (response?.result !== 'OK') {
            this.error = true;
          }
        })
      )
      .subscribe();
  }
}
