import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '../interfaces/api.interface';
import { AdminSettingsComponent } from './dashboard/admin-settings/admin-settings.component';
import { BalanceComponent } from './dashboard/balance/balance.component';
import { DetailsComponent } from './dashboard/balance/details/details.component';
import { ContactComponent } from './dashboard/contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmployeeEditComponent } from './dashboard/employees/edit/edit.component';
import { EmployeeComponent } from './dashboard/employees/employee/employee.component';
import { EmployeesComponent } from './dashboard/employees/employees.component';
import { ExportComponent } from './dashboard/export/export.component';
import { FaqComponent } from './dashboard/faq/faq.component';
import { PaymentsComponent } from './dashboard/payments/payments.component';
import { RestaurantEditComponent } from './dashboard/restaurants/edit/edit.component';
import { RestaurantComponent } from './dashboard/restaurants/restaurant/restaurant.component';
import { RestaurantsComponent } from './dashboard/restaurants/restaurants.component';
import { ReviewsComponent } from './dashboard/reviews/reviews.component';
import { StatisticsComponent } from './dashboard/statistics/statistics.component';
import { TransactionsComponent } from './dashboard/transactions/transactions.component';
import { UserComponent } from './dashboard/users/user/user.component';
import { UsersComponent } from './dashboard/users/users.component';
import { WorkplacesComponent } from './dashboard/workplaces/workplaces.component';
import { LogoutComponent } from './logout/logout.component';
import { ConfirmComponent } from './pre-login/confirm/confirm.component';
import { InvitationComponent } from './pre-login/invitation/invitation.component';
import { LoginComponent } from './pre-login/login/login.component';
import { RegisterComponent } from './pre-login/register/register.component';
import { AuthGuard } from './_helpers/auth.guard';
import { AuthenticationService } from './_services/authentication.service';

const routes: Routes = [
  { path: 'logout', component: LogoutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'invitation', component: InvitationComponent },
  { path: 'confirm', component: ConfirmComponent },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      // admin only
      {
        path: 'admin/settings',
        component: AdminSettingsComponent,
        data: { roles: [Role.Admin] },
      },
      {
        path: 'balance/:type/:strid',
        component: DetailsComponent,
        data: { roles: [Role.Admin] },
      },
      {
        path: 'balance',
        component: BalanceComponent,
        data: { roles: [Role.Admin] },
      },
      {
        path: 'export',
        component: ExportComponent,
        data: { roles: [Role.Admin] },
      },

      // managers
      {
        path: 'statistics',
        component: StatisticsComponent,
        data: { roles: [Role.Admin, Role.Manager] },
      },
      {
        path: 'restaurants/edit/:strid',
        component: RestaurantEditComponent,
        data: { roles: [Role.Admin, Role.Manager] },
      },
      {
        path: 'restaurants/:strid',
        component: RestaurantComponent,
        data: { roles: [Role.Admin, Role.Manager] },
      },
      {
        path: 'restaurants',
        component: RestaurantsComponent,
        data: { roles: [Role.Admin, Role.Manager] },
      },
      {
        path: 'employees/edit/:guid',
        component: EmployeeEditComponent,
        data: { roles: [Role.Admin, Role.Manager] },
      },
      {
        path: 'employees/:guid',
        component: EmployeeComponent,
        data: { roles: [Role.Admin, Role.Manager] },
      },
      {
        path: 'employees',
        component: EmployeesComponent,
        data: { roles: [Role.Admin, Role.Manager] },
      },
      {
        path: 'workplaces',
        component: WorkplacesComponent,
        data: { roles: [Role.Employee] },
      },
      {
        path: 'reviews',
        component: ReviewsComponent,
        data: { roles: [Role.Admin, Role.Manager] },
      },
      {
        path: 'payments',
        component: PaymentsComponent,
        data: { roles: [Role.Employee] },
      },
      { path: 'contact', component: ContactComponent },
      { path: 'faq', component: FaqComponent },

      {
        path: 'users/:id',
        component: UserComponent,
        data: { roles: [Role.Admin] },
      },
      {
        path: 'users',
        component: UsersComponent,
        data: { roles: [Role.Admin] },
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        data: { roles: [Role.Admin] },
      },

      // everyone
      { path: '**', redirectTo: '/statistics' },
    ],
  },

  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private authenticationService: AuthenticationService) {}
}
