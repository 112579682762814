import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LottieModule } from 'ngx-lottie';
import { ModalComponent } from './modal.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}
@NgModule({
  declarations: [ModalComponent],
  imports: [CommonModule, LottieModule.forRoot({ player: playerFactory })],
  exports: [ModalComponent],
})
export class ModalModule {}
