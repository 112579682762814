import { Component, Input, OnInit } from '@angular/core';
import {
  Employee,
  EmployeeStatus,
  User,
} from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import { TableConfig } from '../../elements/data-table/data-table.interface';
import { ModalService } from '../../modal/modal.service';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit {
  employees: Employee[];
  totalItems: number = 0;
  itemsPerPage = 15;
  user: User = new User();

  @Input()
  restaurantStrid: string | undefined;

  private _tableConfig: TableConfig | undefined = undefined;

  get tableConfig() {
    return this._tableConfig;
  }

  constructor(
    private apiService: ApiService,
    private modalService: ModalService
  ) {
    this.employees = [];
  }

  updateEmployeeStatus(guid: string, status: EmployeeStatus) {
    this.apiService.setEmployeeStatus(guid, status).subscribe(
      (response) => {
        const idx = this.employees.findIndex((e) => e.guid == guid);
        if (status === EmployeeStatus.DELETED) {
          this.employees.splice(idx, 1);
          return;
        }
        if (idx > -1) {
          this.employees[idx] = {
            ...this.employees[idx],
            ...response,
          };
          this.modalService.success('Status updated!');
        }
      },
      () => {
        this.modalService.error('Something went wrong!');
      }
    );
  }

  loadData(sort?: SortEvent) {
    this.apiService
      .getEmployees(
        {
          strid: this.restaurantStrid,
        },
        sort?.sortBy,
        sort?.sortDirection,
        sort?.page,
        this.itemsPerPage
      )
      .subscribe(
        (response) => {
          this.employees = response.employees;
          this.totalItems = response.total;
        },
        () => {
          console.log('err');
        }
      );
  }

  ngOnInit(): void {
    this._tableConfig = {
      title: 'Employees list',
      headerButtons: [
        {
          title: 'New',
          icon: 'fa-plus',
          link: ['/employees', 'edit', 'new'],
          queryParams: {
            strid: this.restaurantStrid,
          },
        },
        //{ title: "Filter", icon: "fa-filter" },
        //{ title: "Export", icon: "fa-external-link-alt" }
      ],
      columns: [
        {
          title: 'Name',
          property: 'name',
          useFirstLastName: true,
          sortable: true,
          filterable: false,
          avatar: {
            property: 'image',
          },
          link: {
            prefix: '/employees',
            property: 'guid',
          },
        },
        {
          title: 'Restaurant',
          property: 'restaurant',
          sortable: true,
          filterable: false,
          fromObject: true,
          hidden: this.restaurantStrid !== undefined,
          link: {
            prefix: '/restaurants',
            property: 'strid',
          },
        },
        {
          title: 'Email',
          property: 'email',
          sortable: true,
          filterable: false,
        },
        {
          title: 'Phone',
          property: 'phone',
          sortable: true,
          filterable: false,
        },
        {
          title: 'Joined',
          property: 'createdOn',
          sortable: true,
          filterable: false,
          isDate: true,
        },
        {
          title: 'Status',
          property: 'status',
          sortable: true,
          filterable: false,
          badge: {
            active: { class: 'bg-primary', icon: 'fa-check' },
            pending: { class: 'bg-warning', icon: 'fa-stream' },
            onhold: { class: 'bg-secondary', icon: 'fa-times' },
          },
        },
        {
          moreOptions: true,
          options: [
            {
              title: 'Set Active',
              class: 'text-primary',
              showOnlyIfNot: {
                property: 'status',
                value: ['active'],
              },
              click: {
                property: 'guid',
                fn: (guid: string) =>
                  this.updateEmployeeStatus(guid, EmployeeStatus.ACTIVE),
              },
            },
            {
              title: 'Set On-Hold',
              class: 'text-primary',
              showOnlyIfNot: {
                property: 'status',
                value: ['pending', 'onhold'],
              },
              click: {
                property: 'guid',
                fn: (guid: string) =>
                  this.updateEmployeeStatus(guid, EmployeeStatus.ONHOLD),
              },
            },

            {
              title: 'Edit',
              link: {
                prefix: '/employees/edit',
                property: 'guid',
              },
            },
            {
              title: 'Delete',
              class: 'text-danger',
              click: {
                property: 'guid',
                fn: (guid: string) =>
                  this.modalService.confirmation(
                    'Are you sure you want to delete this record?',
                    () =>
                      this.updateEmployeeStatus(guid, EmployeeStatus.DELETED)
                  ),
              },
            },
          ],
        },
      ],
    };

    this.loadData();
  }
}
