import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Balance, ExportList } from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import {
  TableColumnAlign,
  TableConfig,
} from '../../elements/data-table/data-table.interface';
import { ModalService } from '../../modal/modal.service';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent implements OnInit {
  exportList: ExportList[] = [];
  totalItems: number = 0;
  itemsPerPage = 15;

  private _tableConfig: TableConfig = {
    title: 'Export list',
    headerButtons: [],
    columns: [
      {
        title: 'ID',
        property: 'id',
        sortable: true,
        filterable: true,
      },
      {
        title: 'Employees',
        property: 'exportedEmployeesCount',
        sortable: false,
        filterable: false,
      },
      {
        title: 'Restaurants',
        property: 'exportedRestaurantsCount',
        sortable: false,
        filterable: false,
      },
      {
        title: 'Amount',
        property: 'amount',
        sortable: false,
        filterable: false,
      },
      {
        title: 'Created On',
        property: 'createdOn',
        sortable: true,
        filterable: true,
        isDate: true,
        isTime: true,
      },
      {
        title: 'Status',
        property: 'status',
        sortable: false,
        filterable: false,
        badge: {
          processing: { class: 'bg-secondary', text: 'processing' },
          ready: { class: 'bg-primary', text: 'ready' },
          voided: { class: 'bg-danger', text: 'voided' },
        },
        align: TableColumnAlign.CENTER,
      },
      {
        moreOptions: true,
        options: [
          {
            title: 'Download CSV',
            class: 'text-primary',
            showOnlyIf: {
              property: 'status',
              value: ['ready'],
            },
            click: {
              property: 's3Key',
              fn: (key: string) => {
                console.log('clicked download');
                this.apiService.download('csv/' + key).subscribe((data) => {
                  var fileName = 'report.csv';
                  console.log(data.headers);
                  const contentDisposition = data.headers.get(
                    'content-disposition'
                  );
                  console.log(contentDisposition);
                  if (contentDisposition) {
                    const fileNameRegex =
                      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = fileNameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                      fileName = matches[1].replace(/['"]/g, '');
                    }
                  }

                  if (data.body == null) {
                    return;
                  }

                  saveAs(data.body, fileName);
                });
              },
            },
          },
          {
            title: 'Void export',
            class: 'text-danger',
            showOnlyIf: {
              property: 'status',
              value: ['ready', 'processing'],
            },
            click: {
              property: 'id',
              fn: (id: number) => {
                this.modalService.confirmation(
                  'Are you sure you want to void this record?',
                  () => {
                    this.apiService.voidExport(id).subscribe((data) => {
                      this.loadData();
                    });
                  }
                );
              },
            },
          },
          {
            title: 'Details',
            link: (item: Balance) => {
              switch (item.entityType) {
                case 'restaurant':
                case 'employee':
                  return '/balance/' + item.entityType + 's/' + item.strid;
                case 'tipeasy':
                  return '/admin/income';
              }
              return null;
            },
          },
          /*{
            title: 'Delete',
            class: 'text-danger',
            link: {
              property: 'guid',

            }
          },*/
        ],
      },
    ],
  };

  get tableConfig() {
    return this._tableConfig;
  }

  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    //
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(sort?: SortEvent) {
    this.apiService
      .exportList(
        [],
        sort?.sortBy,
        sort?.sortDirection,
        sort?.page,
        this.itemsPerPage
      )
      .subscribe(
        (response) => {
          this.exportList = response.list;
          this.totalItems = response.total;
        },
        () => {
          console.log('err');
        }
      );
  }
}
