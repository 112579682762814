import { Component, Input, OnInit } from '@angular/core';
import { Employee } from '../../../interfaces/api.interface';
import { SortEvent } from '../../elements/data-table/data-table.component';
import { TableConfig } from '../../elements/data-table/data-table.interface';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-workplaces',
  templateUrl: './workplaces.component.html',
  styleUrls: ['./workplaces.component.scss'],
})
export class WorkplacesComponent implements OnInit {
  workplaces: Employee[];
  totalItems: number = 0;
  itemsPerPage = 15;

  @Input()
  restaurantStrid: string | undefined;

  private _tableConfig: TableConfig | undefined = undefined;

  get tableConfig() {
    return this._tableConfig;
  }

  constructor(private apiService: ApiService) {
    this.workplaces = [];
  }

  loadData(sort?: SortEvent) {
    this.apiService
      .getWorkplaces(
        {
          strid: this.restaurantStrid,
        },
        sort?.sortBy,
        sort?.sortDirection,
        sort?.page,
        this.itemsPerPage
      )
      .subscribe(
        (response) => {
          this.workplaces = response.employees;
          this.totalItems = response.total;
        },
        () => {
          console.log('err');
        }
      );
  }

  ngOnInit(): void {
    this._tableConfig = {
      title: 'My Workplaces',
      headerButtons: [],
      columns: [
        {
          title: 'Name',
          property: 'name',
          useFirstLastName: true,
          sortable: true,
          filterable: false,
          avatar: {
            property: 'image',
          },
          link: {
            prefix: '/employees',
            property: 'guid',
          },
        },
        {
          title: 'Restaurant',
          property: 'restaurant',
          sortable: true,
          filterable: false,
          fromObject: true,
          hidden: this.restaurantStrid !== undefined,
          link: {
            prefix: '/restaurants',
            property: 'strid',
          },
        },
        {
          title: 'Email',
          property: 'email',
          sortable: true,
          filterable: false,
        },
        {
          title: 'Phone',
          property: 'phone',
          sortable: true,
          filterable: false,
        },
        {
          title: 'Joined',
          property: 'createdOn',
          sortable: true,
          filterable: false,
          isDate: true,
        },
        {
          title: 'Status',
          property: 'status',
          sortable: true,
          filterable: false,
          badge: {
            active: { class: 'bg-primary', icon: 'fa-check' },
            pending: { class: 'bg-warning', icon: 'fa-stream' },
            onhold: { class: 'bg-secondary', icon: 'fa-times' },
          },
        },
      ],
    };

    this.loadData();
  }
}
