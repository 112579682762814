<div class="row">
    <div class="col-12 mb-3">
        <div class="card">
            <div class="card-body">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/employees']">Employees</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{ f.firstName.value }}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>

<div class="row g-0 h-100 align-items-stretch">
    <div class="col-lg-12">
        <div class="card h-100">
            <div class="card-header">
                <h5 class="mb-0">Profile</h5>
            </div>
            <div class="card-body bg-light">
                <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()" method="POST" action="/">
                    <input type="hidden" name="guid" formControlName="guid">
                    <div class="mb-3">
                        <dropzone [image]="profileImage" [event]="dropZoneEvent" (response)="onResponse($event)"
                            [type]="'employees'"></dropzone>
                    </div>
                    <div class="mb-3"><label class="form-label" for="form-gender">Gender</label><select
                            [ngClass]="{ 'is-invalid': submitted && f.gender.errors }" class="form-select"
                            formControlName="gender" id="form-gender">
                            <option value="">Select your gender ...</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select></div>

                    <div class="mb-3"><label class="form-label" for="form-first-name">First Name</label><input
                            class="form-control" type="text" formControlName="firstName" id="form-first-name"
                            placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                            id="form-first-name"></div>
                    <div class="mb-3"><label class="form-label" for="form-last-name">Last Name</label><input
                            class="form-control" type="text" formControlName="lastName" name="lastName"
                            placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                            id="form-last-name"></div>
                    <div class="mb-3"><label class="form-label" for="form-address">Address</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }" class="form-control" type="text"
                            formControlName="address" name="address" placeholder="Address" id="form-address">
                    </div>
                    <div class="mb-3"><label class="form-label" for="form-phone">Phone</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" class="form-control" type="text"
                            formControlName="phone" name="phone" placeholder="Phone" id="form-phone"></div>
                    <div class="mb-3"><label class="form-label" for="form-email">E-Mail</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control" type="text"
                            formControlName="email" name="email" placeholder="E-Mail" id="form-email"></div>
                    <div class="mb-3"><label class="form-label" for="form-occupation">Occupation</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.occupation.errors }" class="form-control"
                            type="text" formControlName="occupation" name="occupation" placeholder="Occupation"
                            id="form-occupation"></div>
                    <div class="mb-3"><label class="form-label" for="form-restaurant">Restaurant</label><select
                            [ngClass]="{ 'is-invalid': submitted && f.restaurantId.errors }" class="form-select"
                            formControlName="restaurantId" id="form-restaurant">
                            <option value="">Select restaurant ...</option>
                            <option *ngFor="let restaurant of restaurants" value="{{ restaurant.id }}">{{
                                restaurant.name }}</option>
                        </select></div>
                    <div class="mb-3"><label class="form-label" for="form-bank-name">Bank Name</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.bankName.errors }" class="form-control"
                            formControlName="bankName" type="text" name="bankName" placeholder="Bank name"
                            id="form-bank-name">
                    </div>
                    <div class="mb-3"><label class="form-label" for="form-bank-iban">Bank IBAN</label><input
                            [ngClass]="{ 'is-invalid': submitted && f.bankIban.errors }" class="form-control"
                            formControlName="bankIban" type="text" name="bankIban"
                            placeholder="LV00 BANK 0000 0000 0000 0" id="form-bank-iban">
                    </div>

                    <div class="mb-3">
                        <div class="form-check form-switch">
                            <input [ngClass]="{ 'is-invalid': submitted && f.allowLogin.errors }"
                                class="form-check-input" formControlName="allowLogin" name="allowLogin"
                                id="form-allow-login" type="checkbox" />
                            <label class="form-check-label" for="form-allow-login">Allow user to login and see his
                                profile for this restaurant <small><br>This will send an invitation to employee
                                    email provider above. If employee is already registered, no email will be
                                    sent.</small></label>
                        </div>
                        <div class="form-check"><input class="form-check-input" id="form-invitation-resend"
                                type="checkbox" value="1" name="resendInvitation"
                                formControlName="resendInvitation" /><label class="form-check-label"
                                for="form-invitation-resend">Re-send invitation email</label>
                            <small><br>If for some reason employee has not received the email, check this option and
                                click Save below. If employee is already registered, no email will be sent.</small>
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="form-check form-switch">
                            <input [ngClass]="{ 'is-invalid': submitted && f.directPayment.errors }"
                                class="form-check-input" formControlName="directPayment" name="directPayment"
                                id="form-direct-payment" type="checkbox" value="1" />
                            <label class="form-check-label" for="form-direct-payment">Direct payments to
                                employee
                                <small><br>By checking this you authorize TipEasy to make direct payouts to your
                                    employees. Please edit your restaurant profile if you want to withhold a commission
                                    for payouts.</small>
                            </label>
                        </div>
                    </div>

                    <div class="mb-3">
                        <button type="submit" [disabled]="loading"
                            class="btn btn-primary btn-block mt-3 w-lg-25 w-sm-100">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Save
                        </button>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
            </div>
        </div>
    </div>
</div>