import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountUpOptions } from 'countup.js';
import QrCodeWithLogo from 'qrcode-with-logos';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  Chart,
  ChartSelector,
  Restaurant,
} from '../../../../interfaces/api.interface';
import { ApiService } from '../../../_services/api.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss'],
})
export class RestaurantComponent implements OnInit {
  optsMoney: CountUpOptions = {
    decimalPlaces: 2,
    suffix: '€',
    duration: 1,
  };
  opts: CountUpOptions = {
    decimalPlaces: 0,
    duration: 1,
  };
  months: ChartSelector[] = [];
  chart = new Subject<Chart>();
  totalTips = 0;
  averageTip = 0;
  tipCount = 0;
  restaurant: Restaurant | undefined;
  qrcode: QrCodeWithLogo | undefined;

  constructor(private apiService: ApiService, private route: ActivatedRoute) {
    for (let i = 0; i < 12; i++) {
      const d = new Date();
      d.setDate(1);
      d.setMonth(d.getMonth() - i);

      this.months.push({
        value: d.getFullYear() + '-' + (d.getMonth() + 1) + '-1',
        text: d.toLocaleString('default', { month: 'long', year: 'numeric' }),
      });
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const strid = params.get('strid') ?? '';

      if (strid) {
        this.apiService
          .getRestaurant(strid)
          .pipe(
            tap((restaurant) => {
              this.restaurant = restaurant;

              this.qrcode = new QrCodeWithLogo({
                image: <HTMLImageElement>document.getElementById('qr'),
                content: environment?.siteUrl + '/r/' + this.restaurant?.strid,
                width: 380,
                nodeQrCodeOptions: {
                  margin: 1,
                  errorCorrectionLevel: 'H',
                },
                logo: {
                  logoSize: 0.25,
                  src: '/assets/tipeasy-qr-2.png',
                },
              });

              this.qrcode.toImage();
            })
          )
          .subscribe();

        this.getTotalSales(undefined, strid);
      }
    });
  }

  downloadQrCode() {
    this.qrcode?.downloadImage(this.restaurant?.strid ?? 'qr');
  }

  onChartPeriodChange(data: string) {
    if (this.restaurant === undefined) {
      return;
    }
    this.getTotalSales(data, this.restaurant.strid);
  }

  getTotalSales(value: string | undefined, strid: string) {
    if (!value) {
      const d = new Date();
      value = d.getFullYear() + '-' + (d.getMonth() + 1) + '-1';
    }
    this.apiService.getTotalSales(value, strid).subscribe(
      (response) => {
        this.chart.next({
          data: response.chart,
        });
        this.totalTips = response.sales.total;
        this.averageTip = response.sales.average;
        this.tipCount = response.sales.count;
      },
      () => {
        console.log('err');
      }
    );
  }
}
