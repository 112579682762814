import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountUpOptions } from 'countup.js';
import QrCodeWithLogo from 'qrcode-with-logos';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  Chart,
  ChartSelector,
  Employee,
  User,
} from '../../../../interfaces/api.interface';
import { ApiService } from '../../../_services/api.service';
import { AuthenticationService } from '../../../_services/authentication.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  profileImage = 'assets/img/team/avatar.png';
  employee: Employee | undefined;

  optsMoney: CountUpOptions = {
    decimalPlaces: 2,
    suffix: '€',
    duration: 1,
  };
  opts: CountUpOptions = {
    decimalPlaces: 0,
    duration: 1,
  };

  months: ChartSelector[] = [];
  chart = new Subject<Chart>();
  totalTips = 0;
  averageTip = 0;
  tipCount = 0;
  qrcode: QrCodeWithLogo | undefined;

  user: User = new User();

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    for (let i = 0; i < 12; i++) {
      const d = new Date();
      d.setDate(1);
      d.setMonth(d.getMonth() - i);

      this.months.push({
        value: d.getFullYear() + '-' + (d.getMonth() + 1) + '-1',
        text: d.toLocaleString('default', { month: 'long', year: 'numeric' }),
      });
    }
    if (this.authenticationService.userValue) {
      this.user = this.authenticationService.userValue;
    }
  }

  onChartPeriodChange(data: string) {
    if (this.employee === undefined) {
      return;
    }
    this.getTotalSales(data, this.employee.guid);
  }

  getTotalSales(value: string | undefined, guid: string) {
    if (!value) {
      const d = new Date();
      value = d.getFullYear() + '-' + (d.getMonth() + 1) + '-1';
    }
    this.apiService.getTotalSales(value, undefined, guid).subscribe(
      (response) => {
        this.chart.next({
          data: response.chart,
        });
        this.totalTips = response.sales.total;
        this.averageTip = response.sales.average;
        this.tipCount = response.sales.count;
      },
      () => {
        console.log('err');
      }
    );
  }

  downloadQrCode() {
    this.qrcode?.downloadImage(this.employee?.guid ?? 'qr');
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const guid = params.get('guid') ?? '';
      if (guid) {
        this.apiService
          .getEmployee(guid)
          .pipe(
            tap((employee) => {
              this.profileImage = employee.image;
              this.employee = employee;

              this.qrcode = new QrCodeWithLogo({
                image: <HTMLImageElement>document.getElementById('qr'),
                content:
                  'https://tipeasy.co/r/' +
                  this.employee?.restaurant?.strid +
                  '/' +
                  this.employee?.guid,
                width: 380,
                nodeQrCodeOptions: {
                  margin: 1,
                  errorCorrectionLevel: 'H',
                },
                logo: {
                  logoSize: 0.25,
                  src: '/assets/tipeasy-qr-2.png',
                },
              });
              this.qrcode.toImage();

              this.getTotalSales(undefined, employee.guid);
            })
          )
          .subscribe();
      }
    });
  }
}
