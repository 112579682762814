<div class="card mb-3">
  <div class="card-header position-relative min-vh-25 mb-7">
    <div class="bg-holder rounded-3 rounded-bottom-0" style="background-image:url(../../assets/img/generic/4.jpg);">
    </div>
    <!--/.bg-holder-->
    <div class="avatar avatar-5xl avatar-profile"><img class="rounded-circle img-thumbnail shadow-sm"
        src="{{ employee?.image }}" width="200" alt="" /></div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-8">
        <h4 class="mb-1">{{ employee?.firstName }} {{ employee?.lastName }}</h4>
        <h5 class="fs-0 fw-normal">{{ employee?.occupation }}</h5>
        <p class="text-500">{{ employee?.restaurant?.name }}</p>
        <div class="border-dashed-bottom my-4 d-lg-none"></div>
      </div>
      <div class="col ps-2 ps-lg-3 text-right">
        <button class="btn btn-outline-primary btn-sm" type="button" (click)="downloadQrCode()">
          <span class="fas fa-download" data-fa-transform="shrink-3 down-2"></span>
          <span class="d-none d-sm-inline-block ms-1">Download QR code</span>
        </button>

        <button *ngIf="user?.role !== 'Employee'" class="btn btn-outline-primary btn-sm mx-2" type="button"
          [routerLink]="['/employees', 'edit', employee?.guid]">
          <span class="fas fa-edit" data-fa-transform="shrink-3 down-2"></span>
          <span class="d-none d-sm-inline-block ms-1">Profile info</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row g-0">
  <div class="col-lg-12 mb-3">
    <chart [chartSelector]="months" [chart]="chart.asObservable()" (onSelectorChange)="onChartPeriodChange($event)">
    </chart>
  </div>
</div>

<div class="row g-3 mb-3">
  <div class="col-sm-6 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-1.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Total tips</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning" [countUp]="totalTips"
          [options]="optsMoney">0
        </div><a *ngIf="user?.role !== 'Employee'" class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See
          all<span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-2.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Average tip</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info" [countUp]="averageTip"
          [options]="optsMoney">0
        </div><a *ngIf="user?.role !== 'Employee'" class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See
          all<span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-4">
    <div class="card overflow-hidden" style="min-width: 12rem">
      <div class="bg-holder bg-card" style="background-image:url(assets/img/illustrations/corner-3.png);">
      </div>
      <!--/.bg-holder-->

      <div class="card-body position-relative">
        <h6>Tip count</h6>
        <div class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info" [countUp]="tipCount" [options]="opts">0
        </div><a *ngIf="user?.role !== 'Employee'" class="fw-semi-bold fs--1 text-nowrap" href="/transactions">See
          all<span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
      </div>
    </div>
  </div>
</div>

<div class="row g-3 mb-3">
  <div class="col-sm-12">
    <reviews *ngIf="employee !== undefined" [latestOnly]=true [employeeGuid]="employee.guid"></reviews>
  </div>
</div>

<img id="qr" class="d-none">