export interface Message {
  message: string;
}

export enum EmployeeStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  ONHOLD = 'onhold',
  DELETED = 'deleted',
}

export interface GenericResponse {
  result: string;
  error?: string;
}

export interface Employee {
  firstName: string;
  lastName: string;
  occupation: string;
  image: string;
  guid: string;
  restaurant?: Restaurant;
  createdOn?: Date;
  allowLogin: boolean;
  directPayment: boolean;
}

export interface Restaurant {
  id: number;
  name: string;
  strid: string;
  background: string;
  logo: string;
  tipAmounts: number[];
  tipCurrency: string;
  tipCurrencySymbol: string;
  employees: Employee[];
  user: User;
  directPayment: boolean;
}

export interface RestaurantRequest {
  strid?: string;
  employeeGuid?: string;
}

export interface ResponseClientToken {
  result: string;
  token: string;
}

export interface BraintreePaymentRequest {
  nonce: string;
  amount: string;
  deviceData?: string;
}

export interface BraintreePaymentResponse {
  result: 'OK' | 'FAILED';
  message?: string;
}

export enum Role {
  Admin = 'Admin',
  Manager = 'Manager',
  Employee = 'Employee',
}

export enum UserStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  ONHOLD = 'onhold',
  DELETED = 'deleted',
}

export class User {
  id: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  username: string | undefined;
  phone: string | undefined;
  role: Role | undefined;
  token?: string | undefined;
  status: UserStatus | undefined;
}

export interface ResponseUsers {
  result: string;
  users: User[];
  total: number;
}

export class LoginRequest {
  username: string | undefined;
  password: string | undefined;
}

export interface Transaction {
  id: number;
  transactionId: string;
  receiptNo: string;
  type: string;
  amount: string;
  currency: string;
  employee: Employee;
  createdOn: Date;
  status: string;
  error: string;
}

export interface ResponseTransactions {
  result: string;
  transactions: Transaction[];
  total: number;
}

export interface ResponseEmployees {
  result: string;
  employees: Employee[];
  total: number;
}

export interface ResponseRestaurants {
  result: string;
  restaurants: Restaurant[];
  total: number;
}

export interface TotalSales {
  date: string;
  total: number;
}

export interface ChartSelector {
  value: string;
  text: string;
}

export interface ChartData {
  date: string;
  total: number;
}

export interface Chart {
  data: ChartData[];
}

export interface TotalSalesResponse {
  result: string;
  chart: ChartData[];
  sales: {
    total: number;
    count: number;
    average: number;
  };
}

export interface Review {
  employee: Employee;
  restaurant: Restaurant;
  review: string;
  stars: number;
  createdOn: Date;
}

export interface ResponseReviews {
  result: string;
  reviews: Review[];
  total: number;
}

export enum SortOrder {
  NONE = '',
  ASC = 'asc',
  DESC = 'desc',
}

export enum EmployeeGender {
  MALE = 'male',
  FEMALE = 'female',
}

export interface SaveEmployee {
  guid: string;
  gender: EmployeeGender;
  occupation: string;
  firstName: string;
  lastName: string;
  image: string;
  address: string;
  phone: string;
  email: string;
  restaurantId: number;
  bankName: string;
  bankIban: string;
  allowLogin: boolean;
  directPayment: boolean;
}

export interface SaveRestaurant {
  id?: number;
  name: string;
  image: string;
  address: string;
  phone: string;
  email: string;
  directPayment: boolean;
  paymentCommission: string;
}

export interface RegistrationData {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  companyName: string;
  companyRegistrationNumber: string;
  phone: string;
}

export interface EmployeeRegistration {
  token: string;
  password: string;
}

export interface RegistrationResponse {
  result: 'OK' | 'FAILED';
  message?: string;
}

export interface EmployeeStatusResponse {
  result: 'OK' | 'FAILED';
  message?: string;
}

export interface DropzoneResponse {
  result: 'OK' | 'FAILED';
  guid?: string;
}

export interface Balance {
  name: string;
  bankName: string;
  bankIban: string;
  entityType: string;
  strid: string;
  image: string;
  balance: string;
  balancePending: string;
}

export interface ResponseBalances {
  result: string;
  balances: Balance[];
  total: number;
}

export interface ExportBalancesRequest {
  reduceBalance: boolean;
  exportEmployees: boolean;
  exportRestaurants: boolean;
}

export interface ExportListResponse {
  result: string;
  list: ExportList[];
  total: number;
}

export interface ExportList {
  id: number;
  exportedEmployeesCount: number;
  exportedRestaurantsCount: number;
  amount: string;
  createdOn: Date;
  status: string;
}

export interface ResponseUrl {
  result: 'OK' | 'FAILED';
  url?: string;
}

export interface ExportBalancesResponse {
  result: 'OK' | 'FAILED';
  message?: string;
}

export interface PayoutData {
  payout: Payout[];
  total: number;
}

export interface Payout {
  amount: string;
  createdOn: Date;
  expectedOn: Date;
  status: string;
  error?: string;
}

export interface PayoutsResponse {
  result: 'OK' | 'NEEDSETUP' | 'NEEDVERIFY' | 'FAILED';
  payouts?: PayoutData;
  features: {
    account: boolean;
    sepa: boolean;
    transfers: boolean;
    payouts: boolean;
  };
  message?: string;
}
