import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { WindowRef } from '../_helpers/window-ref';
import { ModalService } from './modal.service';

@Component({
  selector: 'tipeasy-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string = '';
  content: string = '';
  animationState = 'enter';
  type = 'warning';
  hidden = true;
  modalRef: any;
  callback?: () => void;

  lottieOptions: AnimationOptions = {
    path: '../../assets/img/animated-icons/warning-light.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
  };

  constructor(
    private modalService: ModalService,
    private myElement: ElementRef<HTMLElement>,
    private winref: WindowRef
  ) {}

  ngOnInit(): void {
    const el = this.myElement.nativeElement.querySelector('.modal');
    this.modalRef = new this.winref.nativeWindow.bootstrap.Modal(el);
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.close();
    this.modalService.remove(this);
  }

  error(content?: string): void {
    this.type = 'error';
    this.open(content);
  }

  success(content?: string): void {
    this.lottieOptions = {
      ...this.lottieOptions,
      path: '../../assets/img/animated-icons/check-primary-light.json',
    };
    this.type = 'success';
    this.open(content);
  }

  info(content?: string): void {
    this.type = 'info';
    this.open(content);
  }

  // open modal
  open(content?: string): void {
    if (this.modalRef) {
      this.modalRef.show();
    }
    this.content = content ?? '';
  }

  // close modal
  close(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  confirmation(content: string, callback: () => void) {
    this.lottieOptions = {
      ...this.lottieOptions,
      path: '../../assets/img/animated-icons/warning-light.json',
    };
    this.type = 'confirmation';
    this.callback = callback;
    this.open(content);
  }

  popup(content?: string): void {
    this.type = 'popup';
    this.open(content);
  }
}
