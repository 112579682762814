<div class="card h-lg-100">
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-auto">
                <h6 class="mb-0">Total Tips</h6>
            </div>
            <div class="col-auto d-flex">
                <select class="form-select form-select-sm select-value me-2">
                    <option *ngFor="let item of chartSelector" value="{{ item.value }}">{{ item.text }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="card-body h-100 pe-0">
        <div class="echart-line-total-sales h-100" data-echart-responsive="true"></div>
    </div>
</div>