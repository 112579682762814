import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import {
  DropzoneResponse,
  Restaurant,
  SortOrder,
} from '../../../../interfaces/api.interface';
import { ApiService } from '../../../_services/api.service';

@Component({
  selector: 'employee-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EmployeeEditComponent implements OnInit {
  employeeForm = this.formBuilder.group({
    guid: '',
    gender: ['', [Validators.required, Validators.pattern(/(male|female)/i)]],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    address: '',
    occupation: '',
    phone: '',
    email: ['', [Validators.required, Validators.email]],
    restaurantId: [0, Validators.required],
    bankName: '',
    bankIban: '',
    allowLogin: false,
    directPayment: false,
    resendInvitation: false,
  });
  profileImage = 'assets/img/team/avatar.png';
  submitted = false;
  error = '';
  loading = false;
  dropZoneEvent = new EventEmitter<string>();
  restaurants: Restaurant[] = [];

  get f() {
    return this.employeeForm?.controls ?? {};
  }

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.apiService
      .getRestaurants(undefined, 'name', SortOrder.ASC, 1, 1000)
      .subscribe((response) => {
        this.restaurants = response.restaurants;

        this.route.paramMap.subscribe((params) => {
          const guid = params.get('guid') ?? '';
          if (guid) {
            this.apiService
              .getEmployee(guid)
              .pipe(
                tap((employee) => {
                  if (employee?.image) {
                    this.profileImage = employee.image;
                  }
                  this.employeeForm.patchValue(employee);
                  this.employeeForm.patchValue({
                    restaurantId: this.restaurants.find(
                      (r) => r.strid == employee?.restaurant?.strid
                    )?.id,
                  });
                })
              )
              .subscribe();
          }
        });
      });
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.employeeForm?.invalid) {
      return;
    }

    this.dropZoneEvent.emit('processQueue');
  }

  onResponse(response: DropzoneResponse) {
    let data;
    if (response?.guid) {
      data = {
        image: response.guid,
        ...this.employeeForm.value,
      };
    } else {
      data = this.employeeForm.value;
    }

    this.loading = true;
    this.apiService.saveEmployee(data).subscribe((response) => {
      this.loading = false;
      this.router.navigate(['/employees']);
    });
  }

  /*dropZoneAddFile(file:any) {
    setTimeout(() => {
    this.dropzone.options.addedfile(file);
    this.dropzone.options.thumbnail(file);
    }, 1000);
  }*/
}
