<div class="card">
  <div class="card-body">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/balance']">Balance</a></li>
        <li class="breadcrumb-item active" aria-current="page">Details</li>
      </ol>
    </nav>
  </div>
</div>


<p>details works!</p>