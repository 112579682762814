<h2 class="pb-4">Contact Us</h2>

<div class="card dark">
  <div class="card-body p-4">
    <div class="row">
      <div class="col-xl-7 col-12">
        <p class="text-dark">
          Whether you would like to receive more information, need support, we are happy to hear
          from you and help any way we can.</p>

        <hr class="my-4">

        <div class="row">
          <div class="col-lg-6">
            <table>
              <tr>
                <td>
                  <div class="px-2">
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M20.2736 1.15303C21.6091 2.48851 22.4356 4.33329 22.4356 6.37073C19.4245 13.6371 13.6459 19.7505 5.81225 22.9981L5.80917 22.9936C3.91981 22.9936 2.0313 22.2737 0.590096 20.8325L0.067348 19.5063C-0.0852138 19.1198 0.0303294 18.5634 0.323955 18.2698L4.17614 14.4153C4.46976 14.1214 4.71011 13.8828 4.71011 13.8828C4.71011 13.8839 4.71095 13.8839 4.71095 13.8839C6.15271 15.3245 8.04066 16.0458 9.93086 16.045C9.93086 16.0442 15.4831 10.4924 15.4845 10.4913C15.4853 8.45276 14.6578 6.60911 13.322 5.27362L17.7093 0.886606C18.0021 0.592698 18.5585 0.477435 18.9449 0.629717L20.2736 1.15303Z"
                        fill="#327EE2" />
                    </svg>
                  </div>
                </td>
                <td>
                  <p class="text-primary m-0 fw-bold fs-3">
                    +371 29 462 908
                  </p>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <p class="fs--1">Working days 9:00 - 17:00</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-lg-6">
            <table>
              <tr>
                <td>
                  <div class="px-2">
                    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0 0.886719L12.2793 8.42754L24.5587 0.886719H0ZM12.2815 11.6288L0.00217305 4.08685H0.00107668V19.1685H24.5608V4.08685L12.2815 11.6288Z"
                        fill="#327EE2" />
                    </svg>
                  </div>
                </td>
                <td>
                  <p class="text-primary m-0 fw-bold fs-3">
                    help@tipeasy.co
                  </p>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <p class="fs--1">You'll hear back within 24 hours</p>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <hr class="my-4">

        <div class="row">
          <div class="col-sm-3 text-dark">
            Requisites
          </div>
          <div class="col-auto">
            <p class="fs--1">
              SIA "TipEasy"<br>
              reģ.Nr. 40203327318, 19.06.2021 <br>
              Jūrmalas gatve 111, Rīga, LV-1029
            </p>
          </div>
        </div>

        <hr class="my-4">


      </div>
      <div class="offset-xl-1 col-xl-4 d-none d-xl-block align-self-center">
        <img width="100%" src="assets/contact-us.png">
      </div>
    </div>
  </div>
</div>