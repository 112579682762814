import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { LogoutComponent } from './logout/logout.component';
import { ModalModule } from './modal/modal.module';
import { PreLoginModule } from './pre-login/pre-login.module';
import { RedirectComponent } from './redirect/redirect.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { WindowRef } from './_helpers/window-ref';
import { AuthenticationService } from './_services/authentication.service';

@NgModule({
  declarations: [AppComponent, RedirectComponent, LogoutComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    PreLoginModule,
    DashboardModule,
    ModalModule,
  ],
  providers: [
    WindowRef,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
