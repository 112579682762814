<div class="card mb-3">
  <div class="card-header">
    <div class="row flex-between-center">
      <div class="col-6 col-sm-auto d-flex align-items-center pe-0">
        <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">{{ tableConfig.title }}</h5>
      </div>
      <div class="col-6 col-sm-auto ms-auto text-end ps-0" *ngIf="tableConfig.headerButtons !== undefined">
        <div id="table-purchases-replace-element" class="">
          <button class="btn btn-falcon-default btn-sm mx-1" type="button"
            *ngFor="let button of tableConfig.headerButtons"
            [routerLink]="button?.link ? processLink(button.link, button) : null"
            (click)="button?.click?.fn ? button.click.fn() : null">
            <span [ngClass]="['fas', button.icon]" data-fa-transform="shrink-3 down-2"></span>
            <span class="d-none d-sm-inline-block ms-1">{{ button.title }}</span></button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pt-0">
    <div class="table-responsive scrollbar" data-list='true'>
      <table class="table table-hover overflow-hidden">
        <thead>
          <tr>
            <ng-container *ngFor="let columnHead of tableConfig.columns">
              <th *ngIf="!columnHead?.hidden" scope="col" class="text-nowrap"
                [class.text-end]="columnHead.align=='right'" [class.text-center]="columnHead.align=='center'"
                (click)="columnHead.sortable ? sort(columnHead.property) : null" [class.sort]="columnHead.sortable"
                [class.asc]="sortBy==columnHead.property && sortDirection==sortDirections.ASC"
                [class.desc]="sortBy==columnHead.property && sortDirection==sortDirections.DESC"
                [attr.data-sort]="columnHead.sortable ? columnHead.property : null">
                {{ columnHead.title }}</th>
            </ng-container>
          </tr>

        </thead>
        <tbody class="list">
          <tr class="align-middle" *ngFor="let columnData of tableContent">
            <ng-container *ngFor="let columnHead of tableConfig.columns">
              <td *ngIf="!columnHead?.hidden" [class.text-nowrap]="!columnHead?.wrap" [ngClass]="columnHead?.property"
                [class.text-end]="columnHead.align=='right'" [class.text-center]="columnHead.align=='center'">
                <div class="d-inline-block" [class.text-wrap]="columnHead?.wrap">

                  <badge *ngIf="columnHead.badge" [class]="columnHead?.badge[columnData[columnHead?.property]]?.class"
                    [tooltipTitle]="columnData[columnHead?.tooltip?.property] || null"
                    [text]="columnHead?.badge[columnData[columnHead?.property]]?.text || columnData[columnHead.property]"
                    [icon]="columnHead?.badge[columnData[columnHead?.property]]?.icon">
                  </badge>

                  <div *ngIf="columnHead.avatar" class="d-flex align-items-center">
                    <div *ngIf="columnData[columnHead.avatar.property] else noAvatarImage" class="avatar avatar-xl">
                      <img class="rounded-circle" src="{{ columnData[columnHead.avatar.property] }}" alt="" />
                    </div>
                    <ng-template #noAvatarImage>
                      <div class="avatar avatar-xl">
                        <div class="avatar-name rounded-circle">
                          <span>
                            <ng-container *ngIf="columnData['firstName'] else justName">
                              {{ columnData['firstName'] | slice: 0:1 }} {{ columnData['lastName'] | slice: 0:1 }}
                            </ng-container>
                            <ng-template #justName>
                              {{ columnData[columnHead.property]?.name | slice: 0:1 }}
                            </ng-template>
                          </span>
                        </div>
                      </div>
                    </ng-template>
                    <div class="ms-2">
                      <a *ngIf="columnHead?.link else noLink"
                        [routerLink]="columnHead?.link ? processLink(columnHead?.link, columnData) : null">
                        <ng-container *ngIf="columnHead.useFirstLastName else notName">
                          {{ columnData["firstName"] }} {{ columnData["lastName"] }}
                        </ng-container>
                        <ng-template #notName>
                          {{ columnData[columnHead.property] }}
                        </ng-template>
                      </a>
                      <ng-template #noLink>
                        <ng-container *ngIf="columnHead.useFirstLastName else notName">
                          {{ columnData["firstName"] }} {{ columnData["lastName"] }}
                        </ng-container>
                        <ng-template #notName>
                          {{ columnData[columnHead.property] }}
                        </ng-template>
                      </ng-template>
                    </div>
                  </div>

                  <div *ngIf="columnHead.fromObject" class="d-flex align-items-center">
                    <div class="avatar avatar-xl">
                      <img *ngIf="columnData[columnHead.property]?.image else noImage" class="rounded-circle"
                        src="{{ columnData[columnHead.property]?.image }}" alt="" />
                      <ng-template #noImage>
                        <div class="avatar-name rounded-circle">
                          <span>
                            {{ columnData[columnHead.property]?.name | slice: 0:1 }}</span>
                        </div>
                      </ng-template>
                    </div>
                    <div class="ms-2 name">
                      <a *ngIf="columnHead?.link else noLink"
                        [routerLink]="[columnHead.link.prefix, columnData[columnHead.property][columnHead?.link?.property]]">
                        <ng-container *ngIf="columnHead.useFirstLastName else notName">
                          {{ columnData[columnHead.property]?.firstName }} {{ columnData[columnHead.property]?.lastName
                          }}
                        </ng-container>
                        <ng-template #notName>
                          {{ columnData[columnHead.property]?.name }}
                        </ng-template>
                      </a>
                      <ng-template #noLink>
                        {{ columnData[columnHead.property]?.name }}
                      </ng-template>
                    </div>
                  </div>

                  <div *ngIf="columnHead.isRating" class="d-flex align-items-center">
                    <span *ngFor="let x of [0,1,2,3,4]" class="fa fa-star"
                      [class.star-full]="x < columnData[columnHead.property]"></span>
                  </div>

                  <ng-container
                    *ngIf="!columnHead.badge && !columnHead.avatar && !columnHead.fromObject && !columnHead.isRating && !columnHead.moreOptions">
                    <small *ngIf="columnHead.small else noSmall">{{ columnData[columnHead.property] }}</small>
                    <ng-template #noSmall>
                      {{ columnHead.isDate ? (columnData[columnHead.property] | date: columnHead.isTime ?
                      'dd/MM/YYYY
                      HH:mm':'dd/MM/YYYY') :
                      (columnData[columnHead.property]) }}
                      {{ columnHead.isMoney && columnData[columnHead.property] != "" ? '€':'' }}
                    </ng-template>
                  </ng-container>

                  <ng-container *ngIf="columnHead.moreOptions">
                    <div class="dropdown font-sans-serif position-static">
                      <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button"
                        data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
                        <span class="fas fa-ellipsis-h fs--1"></span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end border py-0">
                        <div class="bg-white py-2">
                          <ng-container *ngFor="let option of columnHead.options">
                            <a *ngIf="
                                 (!option?.showOnlyIfNot || !option.showOnlyIfNot.value.includes(columnData[option.showOnlyIfNot.property]))
                              && (!option?.showOnlyIf || option.showOnlyIf.value.includes(columnData[option.showOnlyIf.property]))
                            " class="dropdown-item"
                              (click)="option?.click?.fn ? option.click.fn(columnData[option.click?.property]) : null"
                              [routerLink]="processLink(option.link, columnData)" [ngClass]="option?.class">
                              {{ option.title }}
                            </a>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      <div *ngIf="withPaging" class="align-items-center container">
        <div class="row">
          <div class="col">
            <p class="mb-0 fs--1">
              <span class="d-none d-sm-inline-block" *ngIf="totalItems>0" data-list-info="data-list-info">{{ (currentPage-1) * itemsPerPage +
                1 }} to {{
                math.min((currentPage-1) * itemsPerPage + itemsPerPage, totalItems) }} of {{ totalItems }}</span>
            </p>
          </div>
          <div class="col-auto d-flex">
            <button class="btn btn-sm btn-primary" type="button" data-list-pagination="prev"
              [class.disabled]="totalItems==0 || currentPage==1 ? true : null" [attr.disabled]="totalItems==0 || currentPage==1 ? true : null"
              (click)="changePage(-1)">
              <span>Previous</span>
            </button>
            <button class="btn btn-sm btn-primary px-4 ms-2" type="button" data-list-pagination="next"
              [class.disabled]="totalItems==0 || currentPage==math.ceil(totalItems/itemsPerPage) ? true : null"
              [attr.disabled]="totalItems==0 || currentPage==math.ceil(totalItems/itemsPerPage) ? true : null" (click)="changePage(1)">
              <span>Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>