import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropzoneFile } from 'dropzone';
import { environment } from '../../../environments/environment';
import { DropzoneResponse } from '../../../interfaces/api.interface';
import { WindowRef } from '../../_helpers/window-ref';

@Component({
  selector: 'dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent implements OnInit {
  dropzone: Dropzone | undefined;
  token: string;

  @Input()
  image = 'assets/img/team/avatar.png';

  @Input()
  type = '';

  @Input()
  event: EventEmitter<string> | undefined;

  @Input()
  avatarType = 'rounded-circle';

  @Output()
  response: EventEmitter<DropzoneResponse> = new EventEmitter();

  constructor(private winref: WindowRef) {
    this.token =
      sessionStorage.getItem('token') ?? localStorage.getItem('token') ?? '';
  }

  processQueue(): void {}

  ngOnInit(): void {
    this.initDropzone();

    this.event?.subscribe((data) => {
      switch (data) {
        case 'processQueue':
          const files = this.dropzone?.getQueuedFiles();
          if (!files || !files.length) {
            this.response.emit({
              result: 'FAILED',
            });
          } else {
            this.dropzone?.processQueue();
          }
          break;
      }
    });
  }

  initDropzone() {
    const dz = this.winref.nativeWindow.Dropzone;
    const component = this;

    var Selector = {
      DROPZONE: '[data-dropzone]',
      DZ_ERROR_MESSAGE: '.dz-error-message',
      DZ_PREVIEW: '.dz-preview',
      DZ_PROGRESS: '.dz-preview .dz-preview-cover .dz-progress',
      DZ_PREVIEW_COVER: '.dz-preview .dz-preview-cover',
    };

    this.dropzone = new dz(Selector.DROPZONE, {
      url: environment.apiUrl + '/api/dashboard/upload?type=' + component.type,
      autoProcessQueue: false,
      previewsContainer: document.querySelector(Selector.DZ_PREVIEW),
      previewTemplate: document.querySelector(Selector.DZ_PREVIEW)?.innerHTML,
      parallelUploads: 1,
      thumbnailHeight: 120,
      thumbnailWidth: 120,
      maxFilesize: 20,
      filesizeBase: 1000,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      thumbnail: (file: DropzoneFile, dataUrl: string) => {
        if (file.previewElement) {
          file.previewElement.classList.remove('dz-file-preview');
          const images: NodeListOf<HTMLImageElement> =
            file.previewElement.querySelectorAll('[data-dz-thumbnail]');
          for (var i = 0; i < images.length; i++) {
            var thumbnailElement = images[i];
            thumbnailElement.alt = file.name;
            thumbnailElement.src = dataUrl;
          }
          setTimeout(function () {
            file.previewElement.classList.add('dz-image-preview');
          }, 1);
        }
      },
      init: function () {
        const that = this;
        this.on('addedfile', function (file: DropzoneFile) {
          if (document.querySelectorAll(Selector.DZ_PREVIEW_COVER).length > 1) {
            document.querySelector(Selector.DZ_PREVIEW_COVER)?.remove();
          }

          if (that.files.length > 1) {
            that.removeFile(that.files[0]);
          }

          return true;
        });

        this.on(
          'success',
          function (file: DropzoneFile, response: DropzoneResponse) {
            component.response.emit(response);
          }
        );
      },
    });
  }
}
