<pre-login>
  <a class="d-flex flex-center mb-4" href="/"><span
      class="font-sans-serif fw-bolder fs-4 d-inline-block">TipEasy</span></a>

  <div *ngIf="!done && this.invitation" class="card theme-wizard mb-5">
    <div class="card-header bg-light pt-3 pb-2">
      <ul class="nav justify-content-between nav-wizard">
        <li *ngFor="let step of steps; let i = index" class="nav-item">
          <a class="nav-link fw-semi-bold" href="{{ step.tab }}" [class.active]="currentStep == i"
            [class.disabled]="i > stepsComplete" [class.done]="currentStep > i" [attr.data-bs-step]="i"
            data-bs-toggle="tab" data-wizard-step="data-wizard-step">
            <span class="nav-item-circle-parent">
              <span class="nav-item-circle">
                <span class="fas" [ngClass]="step.icon"></span>
              </span>
            </span>
            <span class="d-none d-md-block mt-1 fs--1">{{ step.title }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body py-4" id="wizard-controller">
      <div class="tab-content">
        <div class="tab-pane active px-sm-3 px-md-5" role="tabpanel" aria-labelledby="wizard-tab1" id="wizard-tab1">
          <form [formGroup]="formGroups[0]">
            <p>To access your account, please fill in the required details!</p>
            <div class="mb-3"><label class="form-label" for="username">Email</label><input class="form-control"
                type="email" name="username" placeholder="Email address ..." value="{{ this.invitation.email }}"
                readonly="true" />
            </div>
            <div class="row g-2">
              <div class="col-6">
                <div class="mb-3"><label class="form-label" for="password">Password</label><input class="form-control"
                    type="password" name="password" placeholder="Password"
                    [ngClass]="{ 'is-invalid': validation && f?.password?.errors }" id="password"
                    formControlName="password" />
                  <div *ngIf="validation && f?.password?.errors" class="invalid-feedback">
                    <div *ngIf="f?.password?.errors?.required">Password is required!</div>
                    <div *ngIf="f?.password?.errors?.tooShort">Password too short!</div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3"><label class="form-label" for="password-confirm">Confirm
                    Password</label><input class="form-control" type="password" name="passwordConfirm"
                    [ngClass]="{ 'is-invalid': validation && f?.confirmPassword?.errors }"
                    placeholder="Confirm Password" id="password-confirm" formControlName="confirmPassword" />
                  <div *ngIf="validation && f?.confirmPassword?.errors" class="invalid-feedback">
                    <div *ngIf="f?.confirmPassword?.errors?.notSame">Passwords do not match!</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-check"><input class="form-check-input" type="checkbox" name="terms"
                [ngClass]="{ 'is-invalid': validation && f?.acceptTerms?.errors }" id="acceptTerms"
                formControlName="acceptTerms" /><label class="form-check-label" for="acceptTerms">I
                accept the <a href="#!">terms </a>and <a href="#!">privacy policy</a></label></div>
          </form>
        </div>
      </div>
    </div>
    <div class="card-footer bg-light" [class.d-none]="currentStep > steps.length-1">
      <div class="px-sm-3 px-md-5">
        <ul class="pager wizard list-inline mb-0">
          <li class="previous">&nbsp;</li>
          <li class="next"><button (click)="onSubmit()" class="btn btn-primary px-5 px-sm-6" type="submit">Submit<span
                class="fas fa-chevron-right ms-2" data-fa-transform="shrink-3"></span></button></li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="done" class="theme-wizard tab-pane text-center px-sm-3 px-md-5" role="tabpanel"
    aria-labelledby="wizard-tab2" id="wizard-tab2">
    <div class="wizard-lottie-wrapper">
      <div class="wizard-lottie mx-auto my-3">
        <ng-lottie [options]="lottieOptions"></ng-lottie>
      </div>
    </div>
    <h4 class="mb-1">Your account registration has been submitted!</h4>
    <p>You can now login with your email and password!</p>
    <p>For any questions please call our office (9:00 - 17:00, Mon - Fri) +371 26573635</p>

    <button [routerLink]="['/']" class="btn btn-primary px-5 px-sm-6">
      Return to login page</button>
  </div>

</pre-login>