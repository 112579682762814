import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AnimationOptions } from 'ngx-lottie';
import { EmployeeRegistration } from '../../../interfaces/api.interface';
import { ModalService } from '../../modal/modal.service';
import { WindowRef } from '../../_helpers/window-ref';
import { ApiService } from '../../_services/api.service';
import { InvitationData } from './invitation.interface';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss'],
})
export class InvitationComponent implements OnInit {
  steps = [
    {
      title: 'Account',
      icon: 'fa-lock',
      tab: '#wizard-tab1',
    },
    {
      title: 'Done',
      icon: 'fa-thumbs-up',
      tab: '#wizard-tab2',
    },
  ];
  stepsComplete = 0;
  currentStep = 0;
  validation = false;
  submitted = false;
  totalTabs = 0;
  invitation: InvitationData | undefined = undefined;
  token: string = '';

  formGroups: FormGroup[] = [
    this.formBuilder.group(
      {
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
      },
      {
        validators: [this.checkPasswords, this.resetValidation.bind(this)],
      }
    ),
  ];

  done = false;

  lottieOptions: AnimationOptions = {
    path: '../../assets/img/animated-icons/celebration.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
  };

  constructor(
    private winref: WindowRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private modalService: ModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'] || '';
    this.invitation = jwt_decode<InvitationData>(this.token);
    const timeNow = Math.floor(new Date().getTime() / 1000);
    if (!this.invitation || timeNow > this.invitation.exp) {
      this.router.navigateByUrl('/');
      return;
    }
  }

  resetValidation(control: AbstractControl): ValidationErrors | null {
    this.validation = false;
    return null;
  }

  checkPasswords(control: AbstractControl): ValidationErrors | null {
    if (control) {
      // here we have the 'passwords' group
      const password = <string>control.get('password')?.value;
      const confirmPassword = control.get('confirmPassword')?.value;
      if (password.length == 0) {
        control.get('password')?.setErrors({ required: true });
        return { required: true };
      }
      if (password.length < 6) {
        control.get('password')?.setErrors({ tooShort: true });
        return { tooShort: true };
      }
      if (password !== confirmPassword) {
        control.get('password')?.setErrors({ notSame: true });
        control.get('confirmPassword')?.setErrors({ notSame: true });
        return { notSame: true };
      } else {
        control.get('password')?.setErrors(null);
        control.get('confirmPassword')?.setErrors(null);
      }
    }
    return null;
  }

  get f() {
    return this?.formGroups?.[this.currentStep]?.controls;
  }

  onSubmit() {
    if (this.formGroups?.[this.currentStep]?.invalid) {
      this.validation = true;
      return;
    }

    try {
      const data: EmployeeRegistration = {
        token: this.token,
        password: this.formGroups[0].controls.password.value,
      };
      this.apiService.employeeRegistration(data).subscribe(
        (response) => {
          if (response.result === 'OK') {
            this.done = true;
            this.currentStep = 2;
          } else {
            this.modalService.error(
              'Error registering account: ' + response.message
            );
          }
        },
        (err) => {
          console.log(err);
          this.modalService.error('Server error, please try again later!');
        }
      );
    } catch (e) {}
  }
}
