import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
})
export class AdminSettingsComponent implements OnInit {
  constructor(private apiService: ApiService) {}

  testEmail(): void {
    this.apiService.sendTestEmail().subscribe(() => {
      // probably nothing
    });
  }

  ngOnInit(): void {}
}
