import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Chart,
  ChartData,
  ChartSelector,
} from '../../../interfaces/api.interface';
import { utils } from '../../_helpers/utils';
import { WindowRef } from '../../_helpers/window-ref';

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  private eventListenerRegistered = false;

  @Input()
  chartSelector: ChartSelector[] = [];

  @Input()
  chart: Observable<Chart> = new Observable();

  @Output()
  onSelectorChange = new EventEmitter<string>();

  constructor(private winRef: WindowRef) {}

  resizeEcharts() {
    var $echarts = document.querySelectorAll('[data-echart-responsive]');

    if ($echarts.length) {
      $echarts.forEach((item) => {
        if (utils.getData(item, 'echart-responsive')) {
          this.winRef.nativeWindow.echarts.init(item).resize();
        }
      });
    }
  }

  ngOnInit(): void {
    this.chart.subscribe((result) => {
      this.drawChart(result.data);
    });

    utils.resize(() => {
      return this.resizeEcharts();
    });
  }

  drawChart(data: ChartData[]) {
    var ECHART_LINE = '.echart-line-total-sales';
    var SELECT_VALUE = '.select-value';
    var $echartsLine = document.querySelector(ECHART_LINE);

    if ($echartsLine) {
      // Get options from data attribute
      var userOptions = utils.getData($echartsLine, 'options');
      var chart = this.winRef.nativeWindow.echarts.init($echartsLine);
      const chartLabels = data.map((d) => d.date);
      const chartValues = data.map((d) => d.total);

      var getDefaultOptions = () => {
        return {
          color: utils.getGrays().white,
          tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            backgroundColor: utils.getGrays()['100'],
            borderColor: utils.getGrays()['300'],
            textStyle: {
              color: utils.getColors().dark,
            },
            borderWidth: 1,
            formatter: function formatter(params: any) {
              return params[0].value + '€';
            },
            transitionDuration: 0,
            position: (
              pos: number[],
              params: any,
              dom: any,
              rect: any,
              size: any
            ) => {
              return utils.getPosition(pos, params, dom, rect, size);
            },
          },
          xAxis: {
            type: 'category',
            data: chartLabels,
            boundaryGap: false,
            axisPointer: {
              lineStyle: {
                color: utils.getGrays()['300'],
                type: 'dashed',
              },
            },
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                // color: utils.getGrays()['300'],
                color: utils.rgbaColor('#000', 0.01),
                type: 'dashed',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: utils.getGrays()['400'],
              formatter: function formatter(value: any) {
                return value;
              },
              margin: 15,
            },
          },
          yAxis: {
            type: 'value',
            axisPointer: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: utils.getGrays()['300'],
                type: 'dashed',
              },
            },
            boundaryGap: false,
            axisLabel: {
              show: true,
              color: utils.getGrays()['400'],
              margin: 15,
              formatter: function formatter(value: any) {
                return value + '€';
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          series: [
            {
              type: 'line',
              data: chartValues,
              lineStyle: {
                color: utils.getColors().primary,
              },
              itemStyle: {
                borderColor: utils.getColors().primary,
                borderWidth: 2,
              },
              symbol: 'circle',
              symbolSize: 10,
              smooth: false,
              hoverAnimation: true,
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: utils.rgbaColor(utils.getColors().primary, 0.2),
                    },
                    {
                      offset: 1,
                      color: utils.rgbaColor(utils.getColors().primary, 0),
                    },
                  ],
                },
              },
            },
          ],
          grid: {
            right: '28px',
            left: '40px',
            bottom: '15%',
            top: '5%',
          },
        };
      };

      this.echartSetOption(chart, userOptions, getDefaultOptions); // Change chart options accordiong to the selected month

      var valueSelect = document.querySelector(SELECT_VALUE);

      if (valueSelect && !this.eventListenerRegistered) {
        this.eventListenerRegistered = true;
        valueSelect.addEventListener(
          'change',
          (e: any) => {
            var value = e?.currentTarget?.value;
            this.onSelectorChange.emit(value);
          }
          //{ once: true }
        );
      }
    }
  }

  echartSetOption(chart: any, userOptions: any, getDefaultOptions: any) {
    const themeController = document.body; // Merge user options with lodash

    chart.setOption(
      this.winRef.nativeWindow._.merge(getDefaultOptions(), userOptions)
    );
    themeController.addEventListener('clickControl', (_ref16) => {
      const control = (_ref16 as any)?.detail.control;

      if (control === 'theme') {
        chart.setOption(
          this.winRef.nativeWindow._.merge(getDefaultOptions(), userOptions)
        );
      }
    });
  }
}
