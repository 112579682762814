import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../interfaces/api.interface';
import { utils } from '../_helpers/utils';
import { WindowRef } from '../_helpers/window-ref';
import { AuthenticationService } from '../_services/authentication.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'tipeasy-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  loading = false;
  user: User = new User();
  userFromApi: User | undefined;
  public sidebarMinimized = false;
  public navItems = [];

  constructor(
    private userService: UserService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private winRef: WindowRef
  ) {
    if (this.authenticationService.userValue) {
      this.user = this.authenticationService.userValue;
    }
  }

  toggleMinimize(e: boolean) {
    this.sidebarMinimized = e;
  }

  ngOnInit() {
    this.loading = true;
    if (!this.user || !this.user?.id) {
      this.router.navigate(['/login']);
      return;
    }

    this.navbarComboInit();
    this.handleNavbarVerticalCollapsed();
  }

  navbarComboInit() {
    var Selector = {
      NAVBAR_VERTICAL: '.navbar-vertical',
      NAVBAR_TOP_COMBO: '[data-navbar-top="combo"]',
      COLLAPSE: '.collapse',
      DATA_MOVE_CONTAINER: '[data-move-container]',
      NAVBAR_NAV: '.navbar-nav',
      NAVBAR_VERTICAL_DIVIDER: '.navbar-vertical-divider',
    };
    var ClassName = {
      FLEX_COLUMN: 'flex-column',
    };
    var navbarVertical = document.querySelector(Selector.NAVBAR_VERTICAL);
    var navbarTopCombo = document.querySelector(Selector.NAVBAR_TOP_COMBO);

    var moveNavContent = function moveNavContent(windowWidth: number) {
      var navbarVerticalBreakpoint = utils.getBreakpoint(navbarVertical);
      var navbarTopBreakpoint = utils.getBreakpoint(navbarTopCombo);

      if (windowWidth < navbarTopBreakpoint) {
        var navbarCollapse = navbarTopCombo?.querySelector(Selector.COLLAPSE);
        var navbarTopContent = navbarCollapse?.innerHTML;

        if (navbarTopContent && navbarCollapse) {
          var targetID = utils.getData(navbarTopCombo, 'move-target');
          var targetElement = document.querySelector(targetID);
          navbarCollapse.innerHTML = '';
          targetElement.insertAdjacentHTML(
            'afterend',
            "\n            <div data-move-container>\n              <div class='navbar-vertical-divider'>\n                <hr class='navbar-vertical-hr' />\n              </div>\n              ".concat(
              navbarTopContent,
              '\n            </div>\n          '
            )
          );

          if (navbarVerticalBreakpoint < navbarTopBreakpoint) {
            var navbarNav = <HTMLElement>(
              document
                ?.querySelector(Selector.DATA_MOVE_CONTAINER)
                ?.querySelector(Selector.NAVBAR_NAV)
            );
            if (navbarNav) {
              utils.addClass(navbarNav, ClassName.FLEX_COLUMN);
            }
          }
        }
      } else {
        var moveableContainer = document.querySelector(
          Selector.DATA_MOVE_CONTAINER
        );

        if (moveableContainer) {
          var _navbarNav = <HTMLElement>(
            moveableContainer.querySelector(Selector.NAVBAR_NAV)
          );

          if (_navbarNav) {
            utils.hasClass(_navbarNav, ClassName.FLEX_COLUMN) &&
              _navbarNav.classList.remove(ClassName.FLEX_COLUMN);
            moveableContainer
              .querySelector(Selector.NAVBAR_VERTICAL_DIVIDER)
              ?.remove();
            const navbarTopComboCollapse = navbarTopCombo?.querySelector(
              Selector.COLLAPSE
            );
            if (navbarTopComboCollapse) {
              navbarTopComboCollapse.innerHTML = moveableContainer.innerHTML;
            }
            moveableContainer.remove();
          }
        }
      }
    };

    moveNavContent(window.innerWidth);
    utils.resize(function () {
      return moveNavContent(window.innerWidth);
    });
  }

  handleNavbarVerticalCollapsed() {
    var Selector = {
      HTML: 'html',
      NAVBAR_VERTICAL_TOGGLE: '.navbar-vertical-toggle',
      NAVBAR_VERTICAL_COLLAPSE: '.navbar-vertical .navbar-collapse',
      ECHART_RESPONSIVE: '[data-echart-responsive]',
    };
    var Events = {
      CLICK: 'click',
      MOUSE_OVER: 'mouseover',
      MOUSE_LEAVE: 'mouseleave',
      NAVBAR_VERTICAL_TOGGLE: 'navbar.vertical.toggle',
    };
    var ClassNames = {
      NAVBAR_VERTICAL_COLLAPSED: 'navbar-vertical-collapsed',
      NAVBAR_VERTICAL_COLLAPSED_HOVER: 'navbar-vertical-collapsed-hover',
    };
    var navbarVerticalToggle = <HTMLElement>(
      document.querySelector(Selector.NAVBAR_VERTICAL_TOGGLE)
    );
    var html = <HTMLElement>document.querySelector(Selector.HTML);
    var navbarVerticalCollapse = document.querySelector(
      Selector.NAVBAR_VERTICAL_COLLAPSE
    );

    if (navbarVerticalToggle) {
      navbarVerticalToggle.addEventListener(Events.CLICK, function (e) {
        navbarVerticalToggle.blur();
        html.classList.toggle(ClassNames.NAVBAR_VERTICAL_COLLAPSED); // Set collapse state on localStorage

        var event = new CustomEvent(Events.NAVBAR_VERTICAL_TOGGLE);
        e?.currentTarget?.dispatchEvent(event);
      });
    }

    if (navbarVerticalCollapse) {
      navbarVerticalCollapse.addEventListener(Events.MOUSE_OVER, function () {
        if (utils.hasClass(html, ClassNames.NAVBAR_VERTICAL_COLLAPSED)) {
          html.classList.add(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
        }
      });
      navbarVerticalCollapse.addEventListener(Events.MOUSE_LEAVE, function () {
        if (utils.hasClass(html, ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER)) {
          html.classList.remove(ClassNames.NAVBAR_VERTICAL_COLLAPSED_HOVER);
        }
      });
    }
  }
}
