import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortOrder } from '../../../interfaces/api.interface';
import { WindowRef } from '../../_helpers/window-ref';
import { Link } from './data-table.interface';

export interface SortEvent {
  sortBy: string;
  sortDirection: SortOrder;
  page: number;
  limit: number;
}
@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  sortBy = '';
  sortDirection: SortOrder = SortOrder.NONE;
  sortDirections = SortOrder;
  constructor(private windowRef: WindowRef) {}
  math = Math;
  currentPage = 1;

  @Input()
  public tableConfig: any;

  @Input()
  public tableContent: any[] = [];

  @Input()
  public totalItems: number = 0;

  @Input()
  public itemsPerPage: number = 15;

  @Input()
  public withPaging = true;

  @Output()
  public onSort: EventEmitter<SortEvent> = new EventEmitter();

  @Output()
  public onRowChange: EventEmitter<any> = new EventEmitter();

  togglePaginationButtonDisable(button: any, disabled: any) {
    button.disabled = disabled;
    button.classList[disabled ? 'add' : 'remove']('disabled');
  }

  sort(column: string) {
    const sortTypes = [SortOrder.NONE, SortOrder.DESC, SortOrder.ASC];
    if (this.sortBy == column) {
      this.sortDirection =
        sortTypes[(sortTypes.indexOf(this.sortDirection) + 1) % 3];
    } else {
      this.sortDirection = sortTypes[1];
    }

    if (this.sortDirection === SortOrder.NONE) {
      this.sortBy = '';
    } else {
      this.sortBy = column;
    }
    this.currentPage = 1;
    this.emit();
  }

  changePage(diff: number) {
    this.currentPage += diff;
    this.emit();
  }

  emit() {
    this.onSort.emit({
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      page: this.currentPage,
      limit: this.itemsPerPage,
    });
  }

  processLink(
    link: string | string[] | ((item: any) => string[]) | Link,
    item: any
  ) {
    if (typeof link === 'string') {
      return link;
    }

    if (typeof link === 'function') {
      return link(item);
    }

    if (Array.isArray(link)) {
      return link;
    }

    if (typeof link === 'object' && !Array.isArray(link)) {
      return [link.prefix, item[link.property]];
    }

    return null;
  }

  ngOnInit(): void {}
}
